import React, { useContext } from 'react';
import { formatViewNumber } from '../../utils/utils';
import CheckoutItem from '../checkout-item/checkout-item.component';
import PromoCode from './promoCode.component';
import { CartContext } from '../../contexts/cart.context';

export const Summary = ({
  priceWithDiscount,
  discount,
  setDiscount,
  subtotal,
  deliveryPrice
}) => {
  const { cartItems } = useContext(CartContext);

  return (
    <div>
      <h3>Résumé de la commande</h3>
      {cartItems.map((cartItem) => (
        <CheckoutItem key={cartItem.id} cartItem={cartItem} isDelivery />
      ))}
      <PromoCode setDiscount={setDiscount} />
      <div>
        <div className="subTotal">Sous-total: {subtotal} €</div>
        <div className="delivery-price">Livraison: {deliveryPrice} €</div>
        {discount > 0 && (
          <div className="discount">
            {`Reduction appliquée: -${formatViewNumber(discount)} €`}
            {/* {`Reduction appliquée ${formatViewNumber(discount)} € (${
              process.env.REACT_APP_DISCOUNT1
            })`} */}
          </div>
        )}
        <div className="total">Total de la commande: {priceWithDiscount} €</div>
      </div>
    </div>
  );
};

export default Summary;
