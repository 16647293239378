import React from 'react';
import {
  cgvTitles,
  definitionText,
  firstULText,
  secondULText,
  thirdULText,
  articleOneText,
  articleTwoText,
  articleThreeText,
  articleFourText,
  articleFiveText,
  articleSixText,
  articleSevenTextOne,
  articleSevenTextTwo,
  articleSevenTextThree,
  articleTenText,
  articleEightText,
  articleNineText,
  articleElevenText,
  articleTwelveText
} from './text-CGV';

import './CGV.styles.scss';

const CGV = () => {
  const {
    title,
    subtitleOne,
    articleOne,
    articleTwo,
    articleThree,
    articleFour,
    articleFive,
    articleSix,
    articleSeven,
    articleSevenSubititleOne,
    articleSevenSubititleTwo,
    articleEight,
    articleNine,
    articleTen,
    articleEleven,
    articleTwelve
  } = cgvTitles;

  const mapText = (tab, cx) => {
    return tab.map((item, index) => {
      return (
        <p className={cx} key={index}>
          {item.text}
        </p>
      );
    });
  };

  const mapUl = (tab) => {
    return tab.map((item, index) => {
      return <li key={index}>{item.text}</li>;
    });
  };

  return (
    <div className="CGV">
      <>
        <h1 className="title">{title}</h1>
        <p className="article">{subtitleOne}</p>
        {mapText(definitionText)}
        <ul>{mapUl(firstULText)}</ul>
      </>

      <>
        <p className="article">{articleOne}</p>
        {mapText(articleOneText)}
      </>

      <>
        <p className="article">{articleTwo}</p>
        {mapText(articleTwoText)}
      </>

      <>
        <p className="article">{articleThree}</p>
        {mapText(articleThreeText)}
      </>

      <>
        <p className="article">{articleFour}</p>
        {mapText(articleFourText)}
      </>

      <>
        <p className="article">{articleFive}</p>
        {mapText(articleFiveText)}
      </>

      <>
        <p className="article">{articleSix}</p>
        {mapText(articleSixText)}
      </>

      <>
        <p className="article">{articleSeven}</p>
        {mapText(articleSevenTextOne, 'italic')}
      </>

      <>
        <p className="article">{articleSevenSubititleOne}</p>
        {mapText(articleSevenTextTwo)}
      </>

      <>
        <p className="subtitle">{articleSevenSubititleTwo}</p>
        {mapText(articleSevenTextThree)}
      </>

      <>
        <p className="article">{articleEight}</p>
        {mapText(articleEightText)}
      </>

      <>
        <p className="article">{articleNine}</p>
        {mapText(articleNineText)}
        <ul>{mapUl(secondULText)}</ul>
      </>

      <>
        <p className="article">{articleTen}</p>
        {mapText(articleTenText)}
      </>

      <>
        <p className="article">{articleEleven}</p>
        {mapText(articleElevenText)}
      </>

      <>
        <p className="article">{articleTwelve}</p>
        {mapText(articleTwelveText)}
        <ul>{mapUl(thirdULText)}</ul>
      </>

      <p className='dateCGV'>Tous droits réservés - 4 juillet 2022</p>
    </div>
  );
};

export default CGV;
