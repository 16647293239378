import { createContext } from 'react';

// import { getCategoriesAndDocuments } from '../utils/firebase/firebase.utils';
import SHOP_DATA from '../shop-data';
export const CategoriesContext = createContext({
  categoriesMap: {}
});

export const CategoriesProvider = ({ children }) => {
  const categoriesMap = SHOP_DATA;
  // const [categoriesMap, setCategoriesMap] = useState(SHOP_DATA);
  // console.log('categoriesMap', categoriesMap)
  // useEffect(() => {
  //   const getCategoriesMap = async () => {
  //     const categoryMap = await getCategoriesAndDocuments();
  //     console.log(categoryMap);
  //     setCategoriesMap(categoryMap);
  //   };

  //   getCategoriesMap();
  // }, []);

  const value = { categoriesMap };
  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};
