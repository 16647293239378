import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FaEnvelope, FaUserCircle } from 'react-icons/fa';
import { BiAt } from 'react-icons/bi';
import Button from '../button/button.component';
import './form-contact.styles.scss';

const initialForm = {
  fullName: '',
  email: '',
  message: ''
};

const FormContact = () => {
  const [isTouchedFields, setIsTouchedFields] = useState({});
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [formContent, setFormContent] = useState(initialForm);

  const { fullName, email, message } = formContent;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormContent((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    setIsTouchedFields((prev) => ({ ...prev, [name]: true }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMsg('');
    setErrorMsg('');

    let isEmptyField = false;

    for (const field in formContent) {
      if (formContent[field] === '') {
        setIsTouchedFields((prev) => ({ ...prev, [field]: true }));
        isEmptyField = true;
      }
    }

    if (isEmptyField) {
      return;
    }

    let templateParams = {
      from_name: email,
      to_name: 'lacanacbd@gmail.com',
      name: fullName,
      message_html: message
    };

    try {
      await emailjs.send(
        'service_iulcrpi',
        'template_w2bdetp',
        templateParams,
        'wBuHaQ5vpkys2OrGo'
      );
      setIsTouchedFields({});
      setFormContent(initialForm);
      setSuccessMsg('Votre message a été envoyé avec succès');
    } catch {
      setErrorMsg("une erreur s'est produite lors de l'envoi");
    }
  };

  return (
    <div className="contact-container">
      <div className="question-contact">
        <h1>Nous contacter</h1>
        <h4>Comment pouvons-nous vous aider ?</h4>
        {successMsg ? (
          <p className="success">{successMsg}</p>
        ) : (
          <p className="red">{errorMsg}</p>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="name-email-container">
          <label>
            <FaUserCircle size={20} />
            <input
              value={fullName}
              name="fullName"
              placeholder="Nom complet *"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="red">
              {!fullName &&
                isTouchedFields.fullName &&
                'Saisir votre nom et prénom'}
            </p>
          </label>

          <label>
            <BiAt size={20} />
            <input
              value={email}
              type="email"
              name="email"
              placeholder="Email *"
              className="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <p className="red errorEmail">
              {!email &&
                isTouchedFields.email &&
                'Entrez une adresse e-mail valide'}
            </p>
          </label>
        </div>

        <FaEnvelope size={20} />
        <textarea
          value={message}
          name="message"
          placeholder="Message *"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="red">
          {!message && isTouchedFields.message && 'Saisir votre demande'}
        </p>
        <p className="red">* champs requis</p>

        <div>
          <Button type="submit">Envoyer</Button>
        </div>
      </form>
    </div>
  );
};
export default FormContact;
