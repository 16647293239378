import React from 'react';
import Guarantee from '../../components/guarantee/guarantee.component';
import AboutUs from '../../components/about-us/about-us.component';

import './footer.styles.scss';

const Footer = () => {
  return (
    <footer>
      <Guarantee />
      <AboutUs />
    </footer>
  );
};

export default Footer;
