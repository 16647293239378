import React, { useContext, useState } from 'react';
import { CartContext } from '../../contexts/cart.context';
import Button from '../../components/button/button.component';

const discountCodes = {
  [process.env.REACT_APP_DISCOUNT1]: 10,
  [process.env.REACT_APP_DISCOUNT2]: 20
};

const PromoCode = ({ setDiscount }) => {
  const { cartTotal } = useContext(CartContext);

  const [isCodePromo, setIsCodePromo] = useState(false);
  const [infoCode, setInfoCode] = useState('');
  const [userCodeInput, setUserCodeInput] = useState('');

  const handlePromoCode = () => {
    if (!isCodePromo) {
      const discountPercent = discountCodes[userCodeInput];

      if (discountPercent) {
        const total = parseFloat(cartTotal);
        const discount = total * (discountPercent / 100);

        setDiscount(discount);
        setIsCodePromo(true);
        setInfoCode(
          `Code de réduction appliqué: ${userCodeInput} -${discountPercent}%`
        );
      } else {
        setInfoCode("Ce code de réduction n'existe pas");
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setUserCodeInput(value);
  };

  return (
    <div>
      <div className="promo">
        <div className="container-promo-items">
          <input
            name="promo"
            value={userCodeInput}
            onChange={handleChange}
            placeholder="Code de réduction"
          />
          <Button
            disabled={!userCodeInput}
            onClick={handlePromoCode}
            className="button-promo"
          >
            Valider
          </Button>
        </div>

        <div className="info-code">{infoCode}</div>
      </div>
    </div>
  );
};

export default PromoCode;
