export const mentionsLegalesText = {
  title: 'MENTIONS LÉGALES',
  subtitle: `Identification de l'éditeur et de l'hébergeur du site:`,
  textOne: `Le site Https://lacanacbd.fr est édité par LEM SARL, au capital de 1500 €, entreprise immatriculée au RCS de Evry sous le numéro909188179, 
  dont le siège social est sis au 2 rue de vaucluse, 91940 Les ulis.`,
  textTwo: `N° de TVA intracommunautaire: FR15909188179.`,
  textThree: `Directeur de la publication: Lois Besson, Gérant de LEM SARL, joignable au 0651156821 ou à l'adresse Lacanacbd@gmail.com.`,
  textFour: `Le site est hébergé par OVH,2 rue kellerman 59100 ROUBAIX. Les informations concernant la collecte et le traitement desdonnées 
  personnelles (politique et déclaration) sont fournies dans la charte de données personnelles du site.`,
  texFive: `Tous droits réservés - 4 juillet 2022`
};
