import '../description-styles.scss';
import React from 'react';

const DescriptionFlor = ({ product }) => {
  return (
    <table className='container-table'>
      <tbody>
        <tr>
          <td className='title'>DESCRIPTION</td>
          <td>{product.mainDescription}</td>
        </tr>
        <tr>
          <td className='title'>TYPE DE CULTURE</td>
          <td>{product.details.cultureType}</td>
        </tr>
        <tr>
          <td className='title'>PROVENANCE</td>
          <td>{product.details.country}</td>
        </tr>
        <tr>
          <td className='title'>TAUX DE CDB</td>
          <td>{product.details.rateCBD}</td>
        </tr>
        <tr>
          <td className='title'>TAUX DE THC</td>
          <td>{product.details.rateTHC}</td>
        </tr>
        <tr className='hidden'><td></td></tr>
      </tbody>
    </table>
  );
};

export default DescriptionFlor;
