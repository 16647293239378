import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { formatViewNumber } from '../../../utils/utils';

const BasicItem = ({
  totalPrice,
  setTotalPrice,
  quantity,
  setQuantity,
  product,
  createProduct,
  productToAdd
}) => {
  const [unitPrice, setUnitPrice] = useState(0);

  useEffect(() => {
    setUnitPrice(product.price);
  }, [product]);

  const handleChangeInput = (e) => {
    const newQuantity =
      e.target.value > 100 ? 100 : parseInt(e.target.value || 0);
    const newPrice = parseFloat(newQuantity * unitPrice) || 0;

    setQuantity(newQuantity);
    setTotalPrice(newPrice);
    createProduct({
      index: productToAdd.id,
      price: productToAdd.price,
      quantityToAdd: newQuantity
    });
  };

  const addItemArrow = () => {
    const newQuantity = quantity < 100 ? quantity + 1 : 100;
    const newPrice = parseFloat(newQuantity * unitPrice) || 0;

    setQuantity(newQuantity);
    setTotalPrice(newPrice);
    createProduct({
      index: productToAdd.id,
      price: productToAdd.price,
      quantityToAdd: newQuantity
    });
  };

  const removeItemArrow = () => {
    const newQuantity = quantity > 0 ? quantity - 1 : 0;
    const newPrice = parseFloat(newQuantity * unitPrice) || 0;

    setQuantity(newQuantity);
    setTotalPrice(newPrice);
    createProduct({
      index: productToAdd.id,
      price: productToAdd.price,
      quantityToAdd: newQuantity
    });
  };

  return (
    <div>
      <Typography
        className="unit-price"
        id="modal-modal-description"
        sx={{ mt: 2 }}
      >
        Prix unitaire: {formatViewNumber(unitPrice) || 0} €
      </Typography>
      <div className="input-quantity-container">
        <p>Quantité:</p>

        {totalPrice !== 0 && (
          <div className="arrow" onClick={removeItemArrow}>
            &#10094;
          </div>
        )}
        <input
          className="input-quantity"
          type="number"
          value={quantity}
          onChange={handleChangeInput}
        ></input>
        <div className="arrow" onClick={addItemArrow}>
          &#10095;
        </div>
      </div>
    </div>
  );
};

export default BasicItem;
