import mainDescription from './mainDescription';

const SHOP_DATA = [
  {
    title: 'vap pen',
    imageFamily: require('./images/family/vap-pen.png'),
    items: [
      {
        id: 300,
        name: 'Cartouche Gelato',
        imageUrl: require('./images/vapPen/Gelato-Cartridge-in-Box.png'),
        price: 33.9,
        details: {
          mainDescription:
            'Croisement entre Sunset Sherbet et une Thin Mint GSC',
          arome: 'Poivrés, agrume, limonènedoor',
          effect: 'Bien être physique aidant l’esprit à se reposer'
        }
      },
      {
        id: 301,
        name: 'Cartouche Girl Scout Cookie',
        imageUrl: require('./images/vapPen/Girl-Scout-Cookie-Cartridge-in-Box.png'),
        price: 33.9,
        details: {
          mainDescription: 'Croisement entre OG Kush et Durban Poison',
          arome: 'Sucrée, terreux, poivrés',
          effect: 'Relaxation de tout le corps'
        }
      },
      {
        id: 302,
        name: 'Cartouche OG Kush',
        imageUrl: require('./images/vapPen/OG-Kush-Cartridge-in-Box.png'),
        price: 33.9,
        details: {
          mainDescription: 'Croisement entre Chem Dawg et Hindu Kush',
          arome: 'Boisée terreux, mycène',
          effect: 'Soulage le stress'
        }
      },
      {
        id: 303,
        name: 'Cartouche Super Lemon Haze',
        imageUrl: require('./images/vapPen/Super-Lemon-Haze-Cartridge-in-Box.png'),
        price: 33.9,
        details: {
          mainDescription:
            'Croisement entre Lemon Skunk Californienne et d’une super Silver Haze',
          arome: 'Parfum de citron avec des touches de pinène',
          effect: `Puissant éveil des sens, stimulant l'esprit et le corps`
        }
      },
      {
        id: 304,
        name: 'Cartouche Tangie',
        imageUrl: require('./images/vapPen/Tangie-Cartridge-in-Box.png'),
        price: 33.9,
        details: {
          mainDescription: 'Croisement entre California Orange et une Skunk',
          arome: 'Fruité (agrumes) avec des notes herbacée',
          effect: 'Apaisants'
        }
      },
      {
        id: 305,
        name: 'Batterie Golden Buds Pen',
        imageUrl: require('./images/vapPen/Golden-Buds-Pen-Battery.png'),
        price: 19.9,
        details: {
          mainDescription: `300 mg de CBD dans clearomiseur de 0,50ml, équivalent à
            6000mg (60%) de CBD dans un flacon de 10 ml. 
            Clearomiseur avec des coils en céramique de haute qualité.
            Composé avec du distillat de CBD (Full Spectrum) associé à des terpènes naturels de la variété.
            Sans additif, sans colorant alimentaire, sans solvants, sans agent conservateur.`,
          material: 'Tube en acier inoxydable, corps de peinture en métal',
          size: '10,50 x 77,00 mm',
          charge: 'Fil 510',
          batteryCapacity: '350mAh',
          led: 'blanc'
        }
      }
    ]
  },
  {
    title: 'huiles',
    imageFamily: require('./images/family/oils.png'),
    items: [
      {
        id: 1,
        name: 'Huile de chanvre 5% CBD',
        imageUrl: require('./images/huiles/Huile-5-chanvre-boutique.jpg'),
        price: 14.9,
        details: {
          mainDescription: `Huile de chanvre « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 5 % de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Chanvre - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 5% / 500mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 5% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 2,
        name: 'Huile de chanvre 10% CBD',
        imageUrl: require('./images/huiles/Huile-10-chanvre-boutique.jpg'),
        price: 26.0,
        details: {
          mainDescription: `Huile de chanvre « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 10% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Chanvre - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 10% / 1000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 10% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 3,
        name: 'Huile de chanvre 20% CBD',
        imageUrl: require('./images/huiles/Huile-20-chanvre-boutique.jpg'),
        price: 44.0,
        details: {
          mainDescription: `Huile de chanvre « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 20% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Chanvre - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 20% / 2000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 20% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 4,
        name: 'Huile de chanvre 30% CBD',
        imageUrl: require('./images/huiles/Huile-30-chanvre-boutique.jpg'),
        price: 63.0,
        details: {
          mainDescription: `Huile de chanvre « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 30% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Chanvre - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 30% / 3000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 30% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 5,
        name: 'Huile de chanvre 40% CBD',
        imageUrl: require('./images/huiles/Huile-30-chanvre-boutique.jpg'),
        price: 85.0,
        details: {
          mainDescription: `Huile de chanvre « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 40% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Chanvre - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 40% / 4000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 40% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 6,
        name: 'Huile de coco 5% CBD',
        imageUrl: require('./images/huiles/Huile-5-coco-boutique.jpg'),
        price: 14.9,
        details: {
          mainDescription: `Huile de CBD « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 5 % de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.
            Flacon de 10ml avec pipette d’huile de coco concentrée à 5% de CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Coco - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 5% / 500mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 5% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 7,
        name: 'Huile coco 10% CBD',
        imageUrl: require('./images/huiles/Huile-10-coco-boutique.jpg'),
        price: 26.0,
        details: {
          mainDescription: `Huile de CBD « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 10% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.
            Flacon de 10ml avec pipette d’huile de coco concentrée à 10% de CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Coco - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 10% / 1000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 10% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 8,
        name: 'Huile de coco 20% CBD',
        imageUrl: require('./images/huiles/Huile-20-coco-boutique.jpg'),
        price: 44.0,
        details: {
          mainDescription: `Huile de CBD « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 20% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.
            Flacon de 10ml avec pipette d’huile de coco concentrée à 20% de CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Coco - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 20% / 2000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 20% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
           Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 9,
        name: 'Huile de coco 30% CBD',
        imageUrl: require('./images/huiles/Huile-30-coco-boutique.jpg'),
        price: 63.0,
        details: {
          mainDescription: `Huile de CBD « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 30% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.
            Flacon de 10ml avec pipette d’huile de coco concentrée à 30% de CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Coco - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 30% / 3000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 30% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      },
      {
        id: 10,
        name: 'Huile de coco 40% CBD',
        imageUrl: require('./images/huiles/Huile-30-coco-boutique.jpg'),
        price: 85.0,
        details: {
          mainDescription: `Huile de CBD « full spectrum » de fabrication française et 100 % naturelle, avec un dosage contenant 40% de CBD. 
            L’huile contenant du CBD est connue pour ses nombreux bienfaits, dont favoriser la relaxation et le sommeil, 
            ou encore lutter contre le stress et l’anxiété.
            Notre huile est dite « full spectrum », c’est à dire qu’elle contient tous les cannabinoïdes y compris du THC < 0,2%. 
            Contrairement à l’huile à l’isolat, l’huile « full spectrum » permet d’avoir un effet d’entourage qui augmente les bienfaits du CBD.
            Flacon de 10ml avec pipette d’huile de coco concentrée à 40% de CBD.`,
          characteristics: `Type de CBD: Full spectrum - 
            Type d’huile: Coco - 
            Type d’extraction: CO₂ supercritique - 
            Concentration en CBD: 40% / 4000mg -  
            Concentration en THC: < 0,2% - 
            Contenance: 10ml - 
            Fabrication: 100% Made in France`,
          content: `Flacon de 10ml avec pipette d’huile de chanvre concentrée à 10% de CBD.`,
          usage: `Déposer 2 à 3 gouttes sous la langue, et attendre idéalement 4 à 5 minutes avant d’ingérer. 
            Vous pouvez également utiliser l’huile localement pour masser une zone du corps. 
            Ce produit n’est pas destiné à diagnostiquer, traiter, guérir ou prévenir une maladie. A consommer dans le cadre d’une alimentation équilibrée.`,
          precaution: `Conserver à température ambiante, au sec, à l’abri de la lumière, éviter les chocs thermiques importants. 
            Produit déconseillé aux enfants et aux femmes enceintes ou allaitantes.`
        }
      }
    ]
  },
  {
    title: 'infusions',
    imageFamily: require('./images/family/infusions.png'),
    items: [
      {
        id: 100,
        name: 'Infusion Rituel de morphée',
        imageUrl: require('./images/infusions/Rituel-Morphee.jpg'),
        price: 14.9,
        details: {
          mainDescription:
            'Infusion 100% naturelle  BIO, conçu pour améliorer le sommeil avec 25% de CBD',
          composition: `Chanvre CBD (Apaise et facilite l’endormissement) / Verveine citronnée (facile l’endormissement) /
            Camomille (aide à préparer la phase de sommeil) / Mélisse (Apaise l'inquiétude) /
            Houblon (aide à l’endormissement) / Fraise (apporte une note de gourmandise) / Figue (sucre naturellement la recette)`,
          advice: `Plongez votre infusion dans une eau frémissante (environ 90°C) pendant 5 à 10 mn,
            environ 1h avant de vous coucher. 
            Ajouter un corps gras (huile de CBD,Coco, lait, miel…) pour une parfaite diffusion des principes actifs du CBD.`
        }
      },
      {
        id: 101,
        name: 'Infusion Elixir Venus',
        imageUrl: require('./images/infusions/ELixir-Venus.jpg'),
        price: 14.9,
        details: {
          mainDescription:
            'Infusion 100% naturelle  BIO, Élixir de Vénus vous aidera à passer des règles en toute sérénité avec ses 25% de CBD',
          composition: `Chanvre CBD (Apaise et facilite l’endormissement) / Feuille de framboisier (décontracte et rééquilibre) / 
            Sauge Officinal (Favorise l’équilibre hormonal) / Achillée Millefeuille (soulage les contractures) / 
            Menthe poivrée (apaise les douleurs) / Camomille (Calme les troubles) /
            Lavande (Relaxe et parfume) / Framboise (Apporte de la gourmandise) / Pomme (légère note acidulée)`,
          advice: `Plongez votre infusion dans une eau frémissante (environ 90°C) pendant 5 à 10 mn,
            environ 1h avant de vous coucher, 
            Ajouter un corps gras (huile de CBD,Coco, lait, miel…) pour une parfaite diffusion des principes actifs du CBD.`
        }
      },
      {
        id: 102,
        name: `Antidote d'Orée`,
        imageUrl: require(`./images/infusions/Antidote-d'Orée.jpg`),
        price: 14.9,
        details: {
          mainDescription: `Une infusion pour la relaxation 100% naturelle, fabriquée en France, avec des plantes et des fruits tous issus de l’agriculture biologique. 
            Antidote d’Orée contient 25% de chanvre CBD`,
          composition: `Bouillon blanc: Calme les voies respiratoires, Chanvre CBD: Apaise le stress et décontracte, Aspérule odorante: 
          Apaise le corps et l'esprit, Menthe poivrée: Calme et apporte une pointe de fraîcheur`,
          advice: `Plongez votre infusion dans une eau frémissante (environ 90°C) pendant 5 à 10 mn,
            environ 1h avant de vous coucher, 
            Ajouter un corps gras (huile de CBD,Coco, lait, miel…) pour une parfaite diffusion des principes actifs du CBD.`
        }
      },
      {
        id: 103,
        name: `Potion d'Eden`,
        imageUrl: require(`./images/infusions/Potion-d'Eden.jpg`),
        price: 14.9,
        details: {
          mainDescription: `Une infusion pour la digestion 100% naturelle, fabriquée en France, avec des plantes et des fruits tous issus de 
            l’agriculture biologique. Potion d’Eden contient 25% de chanvre CBD.`,
          composition: `Chanvre CBD: Apaise le stress et les contractures, Menthe poivrée: Facilite la digestion, Camomille: 
          Apaise les ballonnements, Aspérule odorante: Stimule le foie, Figue: Sucre naturellement la recette, Orange: Apporte une touche d'agrume`,
          advice: `Plongez votre infusion dans une eau frémissante (environ 90°C) pendant 5 à 10 mn,
            environ 1h avant de vous coucher, 
            Ajouter un corps gras (huile de CBD,Coco, lait, miel…) pour une parfaite diffusion des principes actifs du CBD.`
        }
      }
    ]
  },
  {
    title: 'cosmetiques',
    imageFamily: require('./images/family/cosmetiques-family.jpg'),
    items: [
      {
        id: 200,
        name: 'Crème Hydratante CBD',
        imageUrl: require('./images/cosmetiques/creme-hydratante-cbd.jpg'),
        price: 25,
        details: {
          mainDescription: `Redécouvrez le plaisir d’une peau hydratée en profondeur. 
          Grâce à l’action profonde du CBD (cannabidiol), naturellement présent dans la plante de cannabis, votre peau est nourrie, 
          apaisée et réparée en profondeur. Son doux parfum floral légèrement fruité ainsi que sa composition clean finiront de vous séduire.
          Contenant: 50ml / CBD 500mg`,
          composition: `Eau - Jus de feuilles d’Aloes** - Huile de graine de chanvre sativa** - Eau de fleur de souci - 
          Stéarate d'éthylhexyle - Beurre de Karité** - Polyglyceryl-3 methylglucose distearate - Hydrogenated Ethylhexyl Olivate*** - 
          Huile d’avocat - Monostéarate de glycérol - Huile de graine tournesol** - Alcool stearylique - Cannabidiol*** - 
          Extrait de graines d’avoine** - Extrait de fleur de soucis** - Extrait de fleur de chanvre** - Acétate de tocophérol - 
          Hyaluronate de sodium - Hydrogenated Olive Oil Unsaponifiables** - Palmitate de rétinol - Saccharomyces Ferment Lysate Filtrate** - 
          Glycérol** - Lécithine - Tocophérol - Extraits de feuilles de Romarin - Gomme de tara - Parfum - Diacétate de glutamate tétrasodique - 
          Acide benzoïque - Acide déhydroacétique - Alcool benzylique`
        }
      },
      {
        id: 201,
        name: 'Huile de massage CBD',
        imageUrl: require('./images/cosmetiques/huile-de-massage-cbd.jpg'),
        price: 25,
        details: {
          mainDescription: `Profitez de tous les bienfaits du CBD dans une huile de massage douce et onctueuse. 
          Constamment sollicités tout au long de la journée, les muscles et articulations peuvent être fragilisés et devenir douloureux. 
          Concentrée en CBD, notre huile de massage vous apaise et détend votre corps. Issu de la plante de chanvre (cannabis), 
          le CBD, également appelé cannabidiol, est reconnu pour ses nombreux bienfaits relaxants.
          Son délicat parfum d’agrumes et de menthe poivrée vous plongera dans un moment de bien-être et de relaxation.
          Contenant: 100ml / CBD 1000mg`,
          composition: `Huile de Graine de Tournesol - Huile de graine de chanvre - Huile de Coco - Huile de Greme de blé - 
          Huile d’avocat - Extrait d’arnica montana ( arnica des montagnes) -Cannabidiol (CBD) - Huile de zeste d'orange amère - 
          Huile de zeste d'orange douce - Huile de fleurs / feuilles d’eucalyptus radiata - Huiles essentielles de clou de girofle - 
          Huile de fleurs / feuilles de menthe poivrée - Huile de zeste de citron - Huile essentielle de romarin`
        }
      },
      {
        id: 202,
        name: 'Masque Exfloliant CBD',
        imageUrl: require('./images/cosmetiques/masque-exfoliant-cbd.jpg'),
        price: 27,
        details: {
          mainDescription: `Plus lumineuse, lisse et débarrassée de ses impuretés, le masque exfoliant au CBD redonne à votre peau tout son éclat. 
          Les acides de fruits vont débarrasser la peau des cellules mortes et nettoyer la peau en profondeur. 
          Le CBD (cannabidiol) issu de la plante de cannabis va quant à lui agir sur les inflammations et les imperfections grâce à ses 
          actions antibactériennes et anti-inflammatoires. Son parfum fruité d’agrumes énergisant ainsi que sa composition clean finiront de vous séduire. 
          Contenant: 50ml / CBD 500mg`,
          composition: `Eau - Huile de graine de chanvre sativa** - Kaolin, Jus de feuilles d'Aloes** - Eau de fleur de lavande - 
          Huile de Germe de Blé - Bentonite - Monostéarate de glycérol - Huile de graine tournesol** - Alcool stéarylique - 
          Extrait de pomme - Acide tartrique - Cetearyl Glucoside - Huile D’avocat - Huile de graine rosier musqué - 
          Huile de pépin de raisin - Cannabidiol*** - Extrait de grain d'avoine** - Extrait de citron - Triticum vulgare bran extract - 
          Extrait de feuille de cannabis** - Jus de grenade - Vitis Vinifera Skin Extract - Extrait de Canneberge à gros fruits - 
          Jus de Curran Noir - Jus de myrtille - Extrait de zeste de citron** - Acide citrique - Saccharomyces Ferment Lysate Filtrate** - Glycérol** - 
          Tocophérol - Alcool cétostéarylique - Extraits de feuilles de Romarin - Gomme de tara - Tetrasodium glutamate diacetate - Parfum - 
          Acide benzoïque - Acide déhydroacétique - Limonène* - Citral* - Alcool benzylique`
        }
      },
      {
        id: 203,
        name: 'Baume Chaud CBD',
        imageUrl: require('./images/cosmetiques/baume-chaud-cbd.jpg'),
        price: 24,
        details: {
          mainDescription: `Vous souffrez souvent de douleurs musculaires et inflammatoires ? Le baume chaud au CBD est la solution naturelle pour vous soulager. 
            Grâce à son effet de chaleur, ses ingrédients 100% naturels et sa composition clean, il deviendra vite un indispensable de votre routine bien-être. 
            L’action de la chaleur va soulager efficacement les douleurs notamment musculaires. Le CBD, ou cannabidiol, issu de la plante de chanvre, possède 
            quant à lui de nombreuses propriétés anti-inflammatoires et aide à apaiser la douleur. 
            L’efficacité de notre baume chaud au CBD réside donc dans le parfait mélange entre nos ingrédients sélectionnés soigneusement et l’action de la chaleur. 
            30ml / CBD 900mg`,
          composition: `Huile de tournesol - Beurre de Karité - Huile de Graines de Chanvre - Cire d’abeille - Huile de Coco - Huile de Graine de Lin 
            - Germe de blés - Huile d’olive - Arnica des montagnes - Extrait de Piment d’ornement - Extrait de feuilles de chanvre - Huile d’écorce de canelle 
            - Huile de clou de girofle -  Huile essentielle et extrait de feuilles d’eucalyptus - Huile essentielle de lavandes - Huile essentielle d’arbre à thé - 
            extrait de feuille de romarin`
        }
      },
      {
        id: 204,
        name: 'Baume Froid CBD',
        imageUrl: require('./images/cosmetiques/baume-froid-cbd.jpg'),
        price: 24,
        details: {
          mainDescription: `Si vous souffrez régulièrement de douleurs articulaires et inflammatoires,  
          le baume froid au CBD est une solution naturelle pour vous apaiser efficacement. 
          L’action du froid va venir soulager efficacement les inflammations et, combinée à la puissance du CBD,  
          va venir atténuer zone douloureuse. Notre formule est enrichie en plantes pour vous offrir une solution 
          100% naturelle et clean pour vous soulager efficacement. Contenant: 30ml / CBD 900mg`,
          composition: `Huile de tournesol - Beurre de Karité - Huile de Graines de Chanvre - Cire d’abeille 
          - Huile de Coco - Huile de Graine de Lin - Germe de blés - Huile d’olive 
          - Extrait de feuille et huile essentielle de menthe poivrée - Arnica des montagnes 
          - Extrait de Piment d’ornement - Extrait de feuilles de chanvre - Huile d’écorce de cannelle 
          - Huile de clou de girofle - Huile essentiel et extrait de feuilles d’eucalyptus 
          - Huile essentielle de lavandes - Huile essentielle d’arbre à thé-* extrait de feuilles de menthes 
          - extrait de feuille de romarin`
        }
      }
    ]
  },
  {
    title: 'fleurs et hasch',
    imageFamily: require('./images/family/flors-family.jpg'),
    items: [
      {
        id: 417,
        name: 'Amnesia',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Amnesia.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4,70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.amnesia,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '5-7%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 416,
        name: 'Zkittlez',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Zkittlez.jpg'),
        prices: [
          { value: 9.0, label: '1g (9,00€/g)' },
          { value: 40.0, label: '5g (8,00€/g)' },
          { value: 70.0, label: '10g (7,00€/g)' },
          { value: 162.5, label: '25g (6,50€/g)' },
          { value: 300.0, label: '50g (6,00€/g)' },
          { value: 550, label: '100g (5,50€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.zkittlezDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '5-7%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 415,
        name: 'Gelato',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Gelato.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4,70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.gelatoDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '5-7%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 414,
        name: 'Mango Haze',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Mango.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4,70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.mangoHaze,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '5-7%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 413,
        name: 'White Widow',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/GP7.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4,70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.whiteWidowDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '7-9%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 412,
        name: 'BlueCheese',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Blueberry.jpg'),
        prices: [
          { value: 7.0, label: '1g (7,00€/g)' },
          { value: 32.5, label: '5g (6,50€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.5, label: '25g (5,50€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 450, label: '100g (4,50€/g)' }
        ],
        lowerPrice: 'A partir de 4,50€',
        mainDescription: mainDescription.BlueCheeseDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '3-5%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 411,
        name: 'Bubblegum',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Bubblegum.jpg'),
        prices: [
          { value: 7.0, label: '1g (7,00€/g)' },
          { value: 32.5, label: '5g (6,50€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.5, label: '25g (5,50€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 450, label: '100g (4,50/g)' }
        ],
        lowerPrice: 'A partir de 4,50€',
        mainDescription: mainDescription.bubbleGumDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '7-8%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 410,
        name: 'Harlequin',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Harlequin.jpg'),
        prices: [
          { value: 7.0, label: '1g (7,00€/g)' },
          { value: 32.5, label: '5g (6,50€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.5, label: '25g (5,50€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 450, label: '100g (4,50/g)' }
        ],
        lowerPrice: 'A partir de 4,50€',
        mainDescription: mainDescription.harlequinDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '7-8%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      // {
      //   id: 409,
      //   name: 'Northern Light',
      //   categoryTitle: 'indoor',
      //   imageUrl: require('./images/fleurs/NorthenLight.jpg'),
      //   prices: [
      //     { value: 6.5, label: '1g (6,50€/g)' },
      //     { value: 30.0, label: '5g (6,00€/g)' },
      //     { value: 55.0, label: '10g (5,50/g)' },
      //     { value: 125.0, label: '25g (5,00€/g)' },
      //     { value: 245.0, label: '50g (4,90/g)' },
      //     { value: 440, label: '100g (4,40/g)' }
      //   ],
      //   lowerPrice: 'A partir de 4,40€',
      //   mainDescription: mainDescription.northernLightDescr,
      //   details: {
      //     cultureType: 'Indoor',
      //     country: 'Suisse',
      //     rateCBD: '8-10%',
      //     rateTHC: '<0,3%'
      //   },
      //   isFlorOrHasch: true
      // },
      {
        id: 408,
        name: 'Small Bud PREMIUM',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Strawberry.jpg'),
        prices: [
          { value: 4.0, label: '1g (4,00€/g)' },
          { value: 15.0, label: '5g (3,00€/g)' },
          { value: 25.0, label: '10g (2,50€/g)' },
          { value: 56.25, label: '25g (2,25€/g)' },
          { value: 100.0, label: '50g (2,00€/g)' },
          { value: 180.0, label: '100g (1,80€/g)' }
        ],
        lowerPrice: 'A partir de 1,80€',
        mainDescription: mainDescription.strawberryDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '6-8%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 407,
        name: 'Trim',
        categoryTitle: 'indoor',
        imageUrl: require('./images/fleurs/Trim.jpg'),
        prices: [
          { value: 3.0, label: '1g (3,00€/g)' },
          { value: 12.5, label: '5g (2,50€/g)' },
          { value: 20.0, label: '10g (2,00€/g)' },
          { value: 37.5, label: '25g (1,50€/g)' },
          { value: 50.0, label: '50g (1,00€/g)' }
        ],
        lowerPrice: 'A partir de 1.00€',
        mainDescription: mainDescription.trimDescr,
        details: {
          cultureType: 'Indoor',
          country: 'Suisse',
          rateCBD: '3-5%',
          rateTHC: '<0,3%',
          taste: undefined
        },
        isFlorOrHasch: true
      },
      {
        id: 406,
        name: 'Cheese',
        categoryTitle: 'greenhouse',
        imageUrl: require('./images/fleurs/Cheese.jpg'),
        prices: [
          { value: 2.5, label: '1g (2,50€/g)' },
          { value: 12.5, label: '5g (2,50€/g)' },
          { value: 25.0, label: '10g (2,50€/g)' },
          { value: 56.25, label: '25g (2,25€/g)' },
          { value: 50.0, label: '50g (1,00€/g)' },
          { value: 100, label: '100g (1,00€/g)' }
        ],
        lowerPrice: 'A partir de 1,00€',
        mainDescription: mainDescription.cheese,
        details: {
          cultureType: 'Green House',
          country: 'Italie',
          rateCBD: '4-6%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 405,
        name: 'Fruit Cake',
        categoryTitle: 'greenhouse',
        imageUrl: require('./images/fleurs/Fruit-Cake.jpg'),
        prices: [
          { value: 2.5, label: '1g (2,50€/g)' },
          { value: 12.5, label: '5g (2,50€/g)' },
          { value: 25.0, label: '10g (2,50€/g)' },
          { value: 56.25, label: '25g (2,25€/g)' },
          { value: 50.0, label: '50g (1,00€/g)' },
          { value: 100, label: '100g (1,00€/g)' }
        ],
        lowerPrice: 'A partir de 1,00€',
        mainDescription: mainDescription.fruitCake,
        details: {
          cultureType: 'Green House',
          country: 'Italie',
          rateCBD: '6-8%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 404,
        name: 'Kiss',
        categoryTitle: 'greenhouse',
        imageUrl: require('./images/fleurs/Kiss.jpg'),
        prices: [
          { value: 5.0, label: '1g (5,00€/g)' },
          { value: 20.0, label: '5g (4,00€/g)' },
          { value: 35.0, label: '10g (3,50€/g)' },
          { value: 75.0, label: '25g (3,00/g)' },
          { value: 100.0, label: '50g (2,00€/g)' },
          { value: 180, label: '100g (1,80€/g)' }
        ],
        lowerPrice: 'A partir de 1,80€',
        mainDescription: mainDescription.kiss,
        details: {
          cultureType: 'Green House',
          country: 'Italie',
          rateCBD: '3-5%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 403,
        name: 'Harlequin X cheese',
        categoryTitle: 'greenhouse',
        imageUrl: require('./images/fleurs/HqCheese.jpg'),
        prices: [
          { value: 2.5, label: '1g (2,50€/g)' },
          { value: 12.5, label: '5g (2,50€/g)' },
          { value: 25.0, label: '10g (2,50€/g)' },
          { value: 56.25, label: '25g (2,25€/g)' },
          { value: 50.0, label: '50g (1,00€/g)' },
          { value: 100, label: '100g (1,00€/g)' }
        ],
        lowerPrice: 'A partir de 1,00€',
        mainDescription: mainDescription.HqCheeseDescr,
        details: {
          cultureType: 'Green House',
          country: 'Italie',
          rateCBD: '4-6%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 402,
        name: 'Lemoncello',
        categoryTitle: 'greenhouse',
        imageUrl: require('./images/fleurs/Limoncello.jpg'),
        prices: [
          { value: 2.5, label: '1g (2,50€/g)' },
          { value: 12.5, label: '5g (2,50€/g)' },
          { value: 25.0, label: '10g (2,50€/g)' },
          { value: 56.25, label: '25g (2,25€/g)' },
          { value: 50.0, label: '50g (1,00€/g)' },
          { value: 100, label: '100g (1,00€/g)' }
        ],
        lowerPrice: 'A partir de 1,00€',
        mainDescription: mainDescription.LemoncelloDescr,
        details: {
          cultureType: 'Green House',
          country: 'Italie',
          rateCBD: '4-6%',
          rateTHC: '<0,3%'
        },
        isFlorOrHasch: true
      },
      {
        id: 398,
        name: 'Marocain',
        categoryTitle: 'hasch',
        imageUrl: require('./images/fleurs/Marocain.jpg'),
        prices: [
          { value: 7.0, label: '1g (7,00€/g)' },
          { value: 30.0, label: '5g (6,00€/g)' },
          { value: 55.0, label: '10g (5,50€/g)' },
          { value: 125.0, label: '25g (5€/g)' },
          { value: 225.0, label: '50g (4,50€/g)' },
          { value: 400, label: '100g (4,00€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.marocainDescr,
        details: {
          cultureType: 'Extraction',
          country: 'Suisse',
          rateCBD: '40,00%',
          rateTHC: '<0,3%',
          taste: undefined
        },
        isFlorOrHasch: true
      },
      {
        id: 399,
        name: 'Pollen',
        categoryTitle: 'hasch',
        imageUrl: require('./images/fleurs/Pollen.jpg'),
        prices: [
          { value: 6.0, label: '1g (6,00€/g)' },
          { value: 27.5, label: '5g (5,50€/g)' },
          { value: 50.0, label: '10g (5,00€/g)' },
          { value: 112.5, label: '25g (4,5€/g)' },
          { value: 200.0, label: '50g (4,00€/g)' },
          { value: 300, label: '100g (3,00€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.pollenDescr,
        details: {
          cultureType: 'Extraction',
          country: 'Suisse',
          rateCBD: '30,00%',
          rateTHC: '<0,3%',
          taste: undefined
        },
        isFlorOrHasch: true
      },
      {
        id: 401,
        name: 'Filtre',
        categoryTitle: 'hasch',
        imageUrl: require('./images/fleurs/Filtre.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4,70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.filtreDescr,
        details: {
          cultureType: 'Extraction',
          country: 'Suisse',
          rateCBD: '75,00%',
          rateTHC: '<0,3%',
          taste: undefined
        },
        isFlorOrHasch: true
      },
      {
        id: 400,
        name: 'Ice O lator',
        categoryTitle: 'hasch',
        imageUrl: require('./images/fleurs/iceOLator.jpg'),
        prices: [
          { value: 8.0, label: '1g (8,00€/g)' },
          { value: 35.0, label: '5g (7,00€/g)' },
          { value: 60.0, label: '10g (6,00€/g)' },
          { value: 137.0, label: '25g (5,48€/g)' },
          { value: 250.0, label: '50g (5,00€/g)' },
          { value: 470, label: '100g (4.70€/g)' }
        ],
        lowerPrice: 'A partir de 4,70€',
        mainDescription: mainDescription.iceOLatorDescr,
        details: {
          cultureType: 'Extraction',
          country: 'Suisse',
          rateCBD: '60,00%',
          rateTHC: '<0,3%',
          taste: undefined
        },
        isFlorOrHasch: true
      }
    ]
  }
];

export default SHOP_DATA;
