import { useNavigate } from 'react-router-dom';
import { scrollTop } from '../../utils/utils';

import './directory-item.styles.scss';

const DirectoryItem = ({ category }) => {
  const navigate = useNavigate();

  const { imageUrl, title, route } = category;

  const onNavigateHandler = () => {
    scrollTop();
    navigate(route);
  };

  return (
    <div onClick={onNavigateHandler} className="directory-item-container">
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
      />
      <div className="body">
        <p className="title-category">{title}</p>
      </div>
    </div>
  );
};

export default DirectoryItem;
