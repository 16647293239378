import React from 'react';
import '../description-styles.scss';

const DescriptionCosmetique = ({ product }) => {
  return (
    <table className="container-table">
      <tbody>
        <tr>
          <td className='title'>DESCRIPTION</td>
          <td>{product.details.mainDescription}</td>
        </tr>
        <tr>
          <td className='title'>COMPOSITION</td>
          <td>{product.details.composition}</td>
        </tr>
        <tr className="hidden">
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default DescriptionCosmetique;
