export const cguTitles = {
  title: `CONDITIONS GÉNÉRALES D'UTILISATION`,
  subtitleOne: `Définitions`,
  articleOne: `Article 1 -  Application des CGU`,
  articleTwo: `Article 2 -  Mentions légales, données personnelles et objet du Site`,
  articleThree: `Article 3 -  Espace membre`,
  articleFour: `Article 4 -  Accès et disponibilité du Site`,
  articleFive: `Article 5 -  Liens hypertextes`,
  articleSix: `Article 6 -  Cookies`,
  articleSeven: `Article 7 -  Droits de propriété intellectuelle`
};

export const definitionText = [
  {
    text: `Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») sont proposées par l'Editeur du Site. L'Utilisateur du Site
    est invité à lire attentivement ces CGU, à les imprimer et/ou à les sauvegarder sur un support durable. L'Utilisateur reconnaît avoir
    pris connaissance des CGU et les accepte intégralement et sans réserve.`
  },
  { text: `On désignera par la suite:` }
];

export const firstULText = [
  {
    text: `« Site » ou « Service »: le site Https://lacanacbd.fr et l'ensemble de ses pages.`
  },
  {
    text: `« Editeur »: LEM, personne morale ou physique responsable de l'édition et du contenu du Site.`
  },
  {
    text: `« Utilisateur »: l'internaute visitant et utilisant les Services du Site.`
  }
];

export const articleOneText = [
  {
    text: `Les présentes CGU ont pour objet de définir les conditions d'accès au Site par les Utilisateurs. 
  L'Editeur se réserve le droit demodifier à tout moment les CGU en publiant une nouvelle version de ces dernières sur le Site. 
  Les CGU applicables àl'Utilisateur sont celles en vigueur au jour de son acceptation.`
  },
  {
    text: `Le Site est d'accès libre et gratuit à tout Utilisateur. L'acquisition d'un produit ou d'un service, ou la création 
  d'un espace membre,ou de manière plus générale la navigation sur le Site suppose l'acceptation, par l'Utilisateur, 
  de l'intégralité des présentes CGU, quireconnaît du même fait en avoir pris pleinement connaissance.`
  },
  {
    text: `Cette acceptation pourra consister par exemple, pour l'Utilisateur, à cocher la case correspondant à la phrase 
  d'acceptation desprésentes CGU, ayant par exemple la mention « je reconnais avoir lu et accepté l'ensemble des conditions 
  générales du Site ». Lefait de cocher cette case sera réputé avoir la même valeur qu'une signature manuscrite de la part de l'Utilisateur.`
  },
  {
    text: `L'Utilisateur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de l'Editeur du présent Site et, sauf pour 
  luid'apporter une preuve contraire, il renonce à les contester en cas de litige.`
  },
  {
    text: `L'acceptation des présentes CGU suppose de la part des Utilisateurs qu'ils jouissent de la capacité juridique nécessaire pour cela. 
  Si l'Utilisateur est mineur ou ne dispose pas de cette capacité juridique, il déclare avoir l'autorisation d'un tuteur, d'un curateur oude son représentant légal.`
  },
  {
    text: `L'Editeur met à la disposition du Client, sur son Site, une charte de confidentialité spécifiant l’ensemble des informations afférentesà 
  l’utilisation des données à caractère personnel du Client collectées par l'Editeur et aux droits dont le Client dispose vis-à-vis deces données personnelles. 
  La politique de confidentialité des données fait partie des CGU. L'acceptation des présentes CGUimplique par conséquent 
  l'acceptation de la politique de confidentialité des données.`
  }
];

export const articleTwoText = [
  {
    text: `Le présent Site est édité par LEM SARL. Les informations légales concernant l'hébergeur et l'Editeur du Site, notamment lescoordonnées et les 
  éventuelles informations de capital et d'immatriculation, sont fournies dans les mentions légales du présentSite.`
  },
  {
    text: `Les informations concernant la collecte et le traitement des données personnelles (politique et déclaration) sont fournies dans 
  lacharte de données personnelles du Site.`
  },
  {
    text: `L'objet du présent Site est déterminé comme « Site de vente en ligne »`
  }
];

export const articleThreeText = [
  {
    text: `L'Utilisateur inscrit au Site (membre) a la possibilité d’y accéder en se connectant grâce à ses identifiants (adresse e-mail définie
    lors de son inscription et mot de passe) ou éventuellement en utilisant des systèmes tels que des boutons de connexion tiers de
    réseaux sociaux. L'utilisateur est entièrement responsable de la protection du mot de passe qu’il a choisi. Il est encouragé à utiliser
    des mots de passe complexes. En cas d’oubli du mot de passe, l'Utilisateur a la possibilité d’en générer un nouveau. Ce mot de
    passe constitue la garantie de la confidentialité des informations contenues dans sa rubrique « mon compte » et l'Utilisateur
    s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, l'Editeur du Site ne pourra être tenu pour responsable
    des accès non autorisés au compte d'un Utilisateur.`
  },
  {
    text: `La création d’un espace personnel est un préalable indispensable à toute commande ou contribution de l'Utilisateur sur le présent
  Site. A cette fin, l'Utilisateur sera invité à fournir un certain nombre d’informations personnelles. Il s’engage à fournir des
  informations exactes.`
  },
  {
    text: `La collecte des données a pour objet la création d’un « compte membre ». Si les données contenues dans la rubrique compte
  membre venaient à disparaître à la suite d’une panne technique ou d’un cas de force majeure, la responsabilité du Site et de son
  Editeur ne pourrait être engagée, ces informations n’ayant aucune valeur probante mais uniquement un caractère informatif. Les
  pages relatives aux comptes membres sont librement imprimables par le titulaire du compte en question mais ne constituent
  nullement une preuve, elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace du service ou des contributions
  par l'Utilisateur.`
  },
  {
    text: `Chaque Utilisateur est libre de fermer son compte et ses données sur le Site. Pour ceci, il doit adresser un e-mail à LEM indiquant
  qu’il souhaite supprimer son compte. Aucune récupération de ses données ne sera alors possible.`
  },
  {
    text: `L'Editeur se réserve le droit exclusif de supprimer le compte de tout Utilisateur qui aurait contrevenu aux présentes CGU
  (notamment, mais sans que cet exemple n’ait un quelconque caractère exhaustif, lorsque l'Utilisateur aura fourni sciemment des
  informations erronées, lors de son inscription et de la constitution de son espace personnel) ou encore tout compte inactif depuis
  au moins une année. Ladite suppression ne sera pas susceptible de constituer un dommage pour l'Utilisateur exclu qui ne pourra
  prétendre à aucune indemnité de ce fait. Cette exclusion n’est pas exclusive de la possibilité, pour l'Editeur, d’entreprendre des
  poursuites d’ordre judiciaire à l’encontre de l'Utilisateur, lorsque les faits l’auront justifié.`
  }
];

export const articleFourText = [
  {
    text: `L'Editeur fait ses meilleurs efforts pour rendre le Site accessible en permanence, sous réserve des opérations de maintenance du
  Site ou des serveurs sur lesquels il est hébergé. En cas d’impossibilité d’accès au Site, en raison de problèmes techniques ou de
  toutes natures, l'Utilisateur ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité.`
  },
  {
    text: `L'Editeur du Site n’est tenu que par une obligation de moyens ; sa responsabilité ne pourra être engagée pour un dommage
  résultant de l'utilisation du réseau Internet tel que perte de données, intrusion, virus, rupture du service, ou autres.`
  },
  {
    text: `L'Utilisateur admet expressément utiliser le Site à ses propres risques et sous sa responsabilité exclusive.`
  },
  {
    text: `Le Site fournit à l'Utilisateur des informations à titre indicatif, avec des imperfections, erreurs, omissions, inexactitudes et autres
  ambivalences susceptibles d'exister. En tout état de cause, LEM ne pourra en aucun cas être tenu responsable:`
  }
];

export const secondULText = [
  {
    text: `de tout dommage direct ou indirect, notamment en ce qui concerne les pertes de profits, le manque à gagner, les pertes de
  clientèle, de données pouvant entre autres résulter de l'utilisation du Site, ou au contraire de l'impossibilité de son utilisation ;`
  },
  {
    text: `d'un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise utilisation, d'une mauvaise configuration de l'ordinateur
  de l'Utilisateur, ou encore de l'emploi d'un navigateur peu usité par l'Utilisateur.`
  }
];

export const articleFiveText = [
  { text: `Le Site peut inclure des liens hypertextes vers d’autres sites.` },
  {
    text: `L'Utilisateur reconnaît par conséquent que l'Editeur ne pourra être tenu responsable de tous dommages ou pertes avérés ou
  allégués, consécutifs à ou en relation avec l'utilisation ou avec le fait d'avoir pris connaissance des contenus, publicités, produits ou
  services disponibles sur ces sites ou sources externes. De même, la responsabilité de l'Editeur du présent Site ne saurait être
  engagée si la visite, par l’Utilisateur, de l’un de ces sites, lui causait un préjudice.`
  },
  {
    text: `Si, en dépit des efforts de l'Editeur, un des liens hypertextes présents sur le Site pointait vers un site ou une source internet dont le
  contenu était ou paraissait non conforme aux exigences de la loi française à un Utilisateur, celui-ci s’engage à prendre
  immédiatement contact avec le directeur de la publication du Site, dont les coordonnées figurent dans les mentions légales du Site,
  afin de lui communiquer l'adresse des pages du site tiers en cause.`
  }
];

export const articleSixText = [
  {
    text: `Un « Cookie » peut permettre l'identification de l'Utilisateur du Site, la personnalisation de sa consultation du Site et l'accélération
  de l’affichage du Site grâce à l'enregistrement d'un fichier de données sur son ordinateur. Le Site est susceptible d'utiliser des «
  Cookies » principalement pour 1) obtenir des statistiques de navigation afin d'améliorer l'expérience de l'Utilisateur, et 2) permettre
  l'accès à un compte de membre et à du contenu qui n'est pas accessible sans connexion.`
  },
  {
    text: `L'Utilisateur reconnaît être informé de cette pratique et autorise l'Editeur du Site à y recourir. L'Editeur s'engage à ne jamais
  communiquer le contenu de ces « Cookies » à des tierces personnes, sauf en cas de réquisition légale.`
  },
  {
    text: `L'Utilisateur peut refuser l'enregistrement de « Cookies » ou configurer son navigateur pour être prévenu préalablement à
  l'acception les « Cookies ». Pour ce faire, l'Utilisateur procédera au paramétrage de son navigateur:`
  }
];

export const thirdULText = [
  {
    text: `Pour Internet Explorer: http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies`
  },
  { text: `Pour Safari: https://support.apple.com/fr-fr/ht1677` },
  {
    text: `Pour Google Chrome: https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on`
  },
  {
    text: `Pour Firefox: https://support.mozilla.org/fr/kb/activer-desactiver-cookies`
  },
  { text: `Pour Opera: http://help.opera.com/Windows/10.20/fr/cookies.html` }
];

export const articleSevenText = [
  {
    text: `Tous les éléments du présent Site appartiennent à l'Editeur ou à un tiers mandataire, ou sont utilisés par l'Editeur sur le Site avec
  l'autorisation de leur propriétaire.`
  },
  {
    text: `Toute représentation, reproduction ou adaptation des logos, contenus textuels, pictographiques ou vidéos, sans que
  cette énumération ne soit limitative, est rigoureusement interdite et s’apparente à de la contrefaçon.`
  },
  {
    text: `Tout Utilisateur qui se rendrait coupable de contrefaçon serait susceptible de voir son accès au site supprimé sans préavis ni
  indemnité et sans que cette exclusion ne puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites
  judiciaires ultérieures à son encontre, à l’initiative de l’Editeur du présent Site ou de son mandataire.`
  },
  {
    text: `Le présent Site utilise des éléments (images, photographies, contenus) dont les crédits reviennent à: Crédits.`
  },
  {
    text: `Les marques et logos contenus dans le Site sont susceptibles d'être déposés par LEM, ou éventuellement par un de ses
  partenaires. Toute personne procédant à leurs représentations, reproductions, imbrications, diffusions et rediffusions encourt les
  sanctions prévues aux articles L.713-2 et suivants du Code de la propriété intellectuelle.`
  }
];
