import React from 'react';
import {
  cguTitles,
  definitionText,
  firstULText,
  secondULText,
  thirdULText,
  articleOneText,
  articleTwoText,
  articleThreeText,
  articleFourText,
  articleFiveText,
  articleSixText,
  articleSevenText
} from './text-CGU';

const CGU = () => {
  const {
    title,
    subtitleOne,
    articleOne,
    articleTwo,
    articleThree,
    articleFour,
    articleFive,
    articleSix,
    articleSeven
  } = cguTitles;

  const mapText = (tab) => {
    return tab.map((item, index) => {
      return <p key={index}>{item.text}</p>;
    });
  };

  const mapUl = (tab) => {
    return tab.map((item, index) => {
      return <li key={index}>{item.text}</li>;
    });
  };

  return (
    <div className="CGU">
      <>
        <h1 className="title">{title}</h1>
        <p className="article">{subtitleOne}</p>
        {mapText(definitionText)}
        <ul>{mapUl(firstULText)}</ul>
      </>

      <>
        <p className="article">{articleOne}</p>
        {mapText(articleOneText)}
      </>

      <>
        <p className="article">{articleTwo}</p>
        {mapText(articleTwoText)}
      </>

      <>
        <p className="article">{articleThree}</p>
        {mapText(articleThreeText)}
      </>

      <>
        <p className="article">{articleFour}</p>
        {mapText(articleFourText)}
        <ul>{mapUl(secondULText)}</ul>
      </>

      <>
        <p className="article">{articleFive}</p>
        {mapText(articleFiveText)}
      </>

      <>
        <p className="article">{articleSix}</p>
        {mapText(articleSixText)}
        <ul>{mapUl(thirdULText)}</ul>
      </>

      <>
        <p className="article">{articleSeven}</p>
        {mapText(articleSevenText)}
      </>

      <p className='dateCGU'>Tous droits réservés - 4 juillet 2022</p>
    </div>
  );
};

export default CGU;
