import React from 'react';
import MentionsLegales from '../../components/mentions-legales/mentions-legales-component';
import CGU from '../../components/CGU/CGU.component';
import './legales-infos.styles.scss';

const LegalesInfos = () => {
  return (
    <div>
      <MentionsLegales />
      <CGU />
    </div>
  );
};

export default LegalesInfos;
