import React from 'react';
import { useNavigate } from 'react-router-dom';
import './about-us.styles.scss';
import FacebookIcon from '../../images/icon-facebook.svg';
import InstagramIcon from '../../images/icon-instagram.svg';
import { scrollTop } from '../../utils/utils';

const AboutUs = () => {
  const navigate = useNavigate();
  
  const onNavigateHandler = (route) => {
    scrollTop();
    navigate(route);
  };

  return (
    <div className="container-about-us">
      <div className="about-us">
        <div className="title">À PROPOS DE NOUS</div>
        <div
          className="link"
          onClick={() => {
            onNavigateHandler('contact');
          }}
        >
          CONTACT
        </div>
        <div
          className="link"
          onClick={() => {
            onNavigateHandler('CGV');
          }}
        >
          CGV
        </div>
        <div
          className="link"
          onClick={() => {
            onNavigateHandler('mentions-legales');
          }}
        >
          MENTIONS LÉGALES
        </div>
        <div
          className="link"
          onClick={() => onNavigateHandler('delivery-infos')}
        >
          LIVRAISON
        </div>
      </div>

      <div className="social-network-container">
        <a
          href="https://www.instagram.com/lacana_cbd/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={InstagramIcon} alt="Logo" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100083152759630"
          target="_blank"
          rel="noreferrer"
        >
          <img src={FacebookIcon} alt="Logo" />
        </a>
      </div>
    </div>
  );
};

export default AboutUs;
