import React from 'react';
import '../description-styles.scss';

const DescriptionOil = ({ product }) => {
  return (
    <table className="container-table">
    <tbody>
      <tr>
        <td className='title'>DESCRIPTION</td>
        <td>{product.details.mainDescription}</td>
      </tr>
      <tr>
        <td className='title'>CARACTÉRISTIQUES</td>
        <td>{product.details.characteristics}</td>
      </tr>
      <tr>
        <td className='title'>CONTENANCE</td>
        <td>{product.details.content}</td>
      </tr>
      <tr>
        <td className='title'>UTILISATION</td>
        <td>{product.details.usage}</td>
      </tr>
      <tr>
        <td className='title'>PRÉCAUTIONS D'UTILISATION</td>
        <td>{product.details.precaution}</td>
      </tr>
      <tr className="hidden">
        <td></td>
      </tr>
    </tbody>
  </table>
  );
};

export default DescriptionOil;
