import React from 'react';
import './guarantee.styles.scss';
import {
  FaHeadset,
  FaShippingFast,
  FaRegHandshake,
  FaRegCreditCard
} from 'react-icons/fa';

const Guarantee = () => {
  return (
    <div className="guarantee">
      <div className="container">
        <FaRegCreditCard className="image" />
        <div className="block-guarantee">
          <div className="title">Paiement 3D Secure</div>
          <div>Vivawallet crypté et ultra-sécurisé</div>
        </div>
      </div>

      <div className="container">
        <FaShippingFast className="image" />
        <div className="block-guarantee">
          <div className="title">Livraison entre 48/72h</div>
          <div>France et Europe</div>
        </div>
      </div>

      <div className="container">
        <FaRegHandshake className="image" />
        <div className="block-guarantee">
          <div className="title">Transport gratuit</div>
          <div>à partir de 50€ d'achat</div>
        </div>
      </div>

      <div className="container">
        <FaHeadset className="image" />
        <div className="block-guarantee">
          <div className="title">Service client 7j/7</div>
          <div>09 79 26 10 53</div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
