const mainDescription = {
  ogKushDscr: `L'OG Kush CBD est une variété de cannabis qui combine les caractéristiques de l'OG Kush avec un taux élevé de cannabidiol (CBD). 
  Elle offre les saveurs terreuses et d'agrumes typiques de l'OG Kush tout en procurant des effets thérapeutiques grâce au CBD, 
  notamment pour soulager l'anxiété, la douleur et l'inflammation, sans provoquer un fort effet psychoactif.`,

  pollenDescr: `Le hash jaune CBD se présente sous forme de résine compacte  qui est caractérisée par sa couleur jaune et une 
  concentration significative de cannabidiol (CBD)En termes de saveur, le hash jaune CBD offre des notes terreuses et épicées
  gelato`,

  marocainDescr: `Le Hash Marocain CBD est spécialement conçu pour offrir les bienfaits du cannabidiol (CBD) sans les effets 
  psychoactifs associés au tétrahydrocannabinol (THC). Cet extrait de résine de chanvre est obtenu à partir de variétés 
  spécifiques cultivées pour leur richesse en CBD. Le Hash Marocain CBD offre une texture soyeuse et une couleur 
  caractéristique. Avec des arômes terreux, épicés. Ce hash CBD propose une expérience aromatique authentique. 
  Il est apprécié pour ses potentiels bienfaits thérapeutiques, offrant la possibilité de soulager le stress, 
  les tensions musculaires et favorisant une sensation de relaxation.`,

  amnesia: `La fleur de CBD Amnesia est une variété de cannabis Sativa réputée pour son taux élevé de cannabidiol (CBD).                                                                                                                 
  Les têtes de la fleur Amnesia CBD sont généralement denses et recouvertes de trichomes, les petits cristaux qui contiennent les cannabinoïdes, y compris le CBD. Les couleurs varient du vert au jaune pâle, avec des pistils orangés qui ajoutent une touche de couleur à l'ensemble.                               
   En termes d'odeur, la fleur Amnesia CBD offre souvent un mélange complexe de parfums, allant des notes terreuses et boisées aux nuances citronnées et épicées. Certains décrivent son arôme comme étant rafraîchissant et énergisant.
  `,

  zkittlezDescr: `La caractéristique principale de la Zkittlez CBD est sa teneur élevée en cannabidiol 
  (CBD)Les bourgeons de Zkittlez CBD sont denses et ultra compacts En termes de goût, la Zkittlez CBD 
  offre généralement une expérience sucrée et fruitée. Les nuances de baies, de raisins et d'agrumes peuvent être présentes, avec parfois une note terreuse en arrière-plan.`,

  bubbleGumDescr: `Notre Bubble Gum CBD est une fleur 
  de CBD de qualité supérieure poussée en intérieur.Elle propose des 
  notes sucrées rappelant les bonbons de notre enfance avec des têtes
  bien denses etcouvertes de trichomes pour un taux de CBD de 8.9%.`,

  gelatoDescr: `La Gelato CBD est une variété de cannabis spécialement cultivée pour sa teneur élevée en cannabidiol 
  (CBD). Cette variété offre une expérience similaire en termes d'arômes et de saveurs, avec des notes sucrées, et de 
  fruits, mais sans les effets psychotropes associés au THC, ces têtes sont vertes et très compact. La Gelato CBD est 
  prisée pour ses potentiels bienfaits thérapeutiques, offrant la possibilité de soulager le stress, l'anxiété et les 
  tensions physiques sans induire d'altérations cognitives. Découvrez la Gelato CBD pour une expérience agréable et 
  apaisante, mariant les qualités aromatiques distinctives de la Gelato avec les bienfaits du CBD.`,

  BlueCheeseDescr: `La BlueCheese est une variété iconique pour tous les consommateurs de CBD, qui nous vient tout droit
  de Suisse où elle a poussé en intérieur avec ses aromes et ses saveurs que tout les amateurs apprécieront.
  Les terpènes sont riches, allant de notes très fruités tout en étant doux avec des notes de myrtilles.
  Des saveurs qui ne vous laisseront pas indifférents.
  En terme d’effets la BlueCheese vous apporte tous les effets du CBD: Anti-stress naturel, anti-
  inflammatoire naturel, apaise le sommeil mais aussi les diverses douleurs !
  elle sera donc parfaite pour vous détendre après une journée de travail ou en Week-end.`,

  whiteWidowDescr: `Voici notre nouvelle fleur: La White Widow CBD. 
  Tout droit arrivée de Suisse, c’est une fleur qui a poussé en intérieur.
  C’est une variété à dominance indica ayant des effets très relaxants et immédiats.
  Alliant notes de fruits exotiques associées à un goût boisé de pin, elle saura conquérir à coup sûr
  les amateurs de CBD qui recherchent un gout naturel, sucré et puissant.
  Elle est reconnaissable grâce à ses têtes denses aux reflets orangés. C’est une des variétés les
  plus qualitatives de notre catalogue du fait de son goût inégalable et de ses effets particulièrement
  appréciables.`,

  harlequinDescr: `La Harlequin CBD est une fleur hybride aux saveurs fruités et acide. 
  Avec 7 % de CBD, elle offre ses propriétés antidouleur et relaxantes. Une fleur parfaire pour vous aider à dormir.
  Elle produit de belles têtes vertes et compactes, avec un délicieux parfum frais et puissant.
  Les nombreux trichomes orange marron de la Harlequin CBD renferment beaucoup de résine. C’est
  dans ces pistils orangés que se concentrent le cannabidiol et ses effets relaxants.
  En effet, cette fleur de CBD puissante propose des effets relaxants qui vous permettrons de vous
  endormir détendu. Grâce à la Harlequin CBD, vous pourrez retrouver un sommeil de qualité et une
  détente optimale.`,

  northernLightDescr: `Voici la Northern Light qui nous vient tout droit de Suisse 
  où elle a poussé en intérieur. Cette fleur d'ascendance indica délivre un effet des plus relaxants
  grâce à son fort taux de cannabidiol. C'est une fleur cbd de qualité supéreure qui dispose d'un goût subtil 
  et envoutant ainsi que d'un effet détente assuré.`,

  strawberryDescr: `La Strawberry CBD est une fleur de greenhouse, poussée sous serre dans les meilleures
   conditions possibles. Elle propose des arômes de fraise et de fruits rouges pour un taux de CBD de 9,2%.
  `,

  trimDescr: `La Trim est un mélange de l’ensemble des variétés disponibles 
  passé au tamis afin de ne récupérer que les fines parties des fleurs. Cette trim 
  est hautement concentré en pistils et pollen et donc riche en cannabinoïdes et terpènes. 
  Ce produit est composé uniquement de fleurs séchées de chanvre. Il est proposé en tant que matière première brute.`,

  filtreDescr: `La résine CBD Filtré est issue d'une extraction à froid qui est ensuite filtrée 3 fois.
  Ce procédé permet d'enlever un maximum de matière végétale et d'atteindre une concentration d’environ 75% de 
  cannabidiol et moins de 0.2% THC. Texture: Résines, crumble, concentrées.`,

  iceOLatorDescr: `Le pollen est une résine concentrée de fleurs et de feuilles de chanvre et constitue 
  un moyen efficace d'obtenir les bienfaits du CDB. La résine CBD Ice O lator   est issue d'une extraction à froid 
  des trims et des têtes inférieure de la plante qui est ensuite filtrée 4 fois à l’aide de tamis avec des mailles 
  de microns de plus en plus fines. Ce procédé permet d'enlever un maximum de matière végétale et d'atteindre une 
  concentration d’environ 75% de cannabidiol et moins de 0.2%.`,

  LemoncelloDescr: `La Lemoncello CBD est une Variété à dominance Sativa, ses notes citronnées lui procurent un goût délicatement acidulé.
  Elle offre un délicieux bouquet olfactif qui rappelle la fameuse et savoureuse liqueur italienne.
  Ses effets à la fois stimulants et relaxants font d’elle une fleur idéale à partager en soirée`,

  HqCheeseDescr: `La harlequin X cheese CBD est une fleur CBD greenhouse(sous-serre) qui est une parfaite alliance entre une chesse 
  aux notes épicées et une harlequin  aux saveurs fruité et acide. Les fleurs de cette variété devaient bien entendu être un peu plus 
  résineuses que la normale, mais aussi, et surtout abriter une riche palettes de terpènes pour offrir aux consommateurs une expérience 
  gustative d’exception.`,

  kiss: `La Kiss est connu pour sa forte teneur en CBD. Cette gamme de cannabis sativa possède des caractéristiques particulières qui 
  sont indiquées pour lutter contre le stress et offrir unpur moment de plaisir et de sérénité.
  Avec un parfum et une saveurprovocateurs, il est parfait pour ceux qui recherchent un goût classique.
  Embrassez votre vie avec notre Kiss !`,

  fruitCake: `La fleur Fruit Cake pour les vrais passionnés du secteur représente un véritable joyau parmi nos productions car elle 
  offre un haut niveau de CBD accompagné d'un arôme unique et incomparable.`,

  cheese: `La cheese cannabis CBD est une variété de fleur CBD greenhouse qui est réputée pour son arôme très original… de fromage! 
  Une fleur CBD unique en son genre ! Une odeur qui ne plaît pas à tout le monde mais plus souvent aux palais plus aguerris en quête 
  de saveurs intenses. Craquez pour l'arôme unique du fromage couplé aux notes épicées et piquantes du citron qui viennent s'y ajouter 
  pour un vrai cocktail de saveurs !`,

  mangoHaze: `Envie d’un voyage sous les tropiques ? Embarquez dans un moment de bien-être avec la fleur de CBD Mango haze. Vous apprécierez 
  durant cette parenthèse relaxante ses parfums à la fois sucrés et acidulés. Les connaisseurs y retrouveront d’ailleurs les tonalités si 
  caractéristiques des Haze. Enfin, le taux de CBD de cette fleur de caractère finira de convaincre tous les amateurs de fleurs.`
};

// charras: `Le charas se présente sous forme de résine sombre et collante. Sa couleur varie du brun foncé au noir.La saveur
// du charas CBD est complexe, avec des notes terreuses, boisées.`,

export default mainDescription;
