import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/home/home.page';
import Authentication from './pages/authentication/authentication.page';
import Shop from './pages/shop/shop.page';
import Checkout from './pages/checkout/checkout.page';
import Contact from './pages/contact/contact.page';
import LegalesInfos from './pages/legales-infos/legales-infos.page';
import SuccessPayment from './pages/success-payment/success-payment.component';
import ErrorPayment from './pages/error-payment/error-payment.component';
import Delivery from './pages/delivery/delivery.page';
import CGV from './pages/CGV/CGV.page';
import DeliveryInfos from './pages/delivery-infos/delivery-infos-page';

const Router = () => {
  return (
    <div className="body">
      <Routes>
        <Route index element={<Home />} />
        <Route path="shop/*" element={<Shop />} />
        <Route path="auth" element={<Authentication />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="contact" element={<Contact />} />
        <Route path="success" element={<SuccessPayment />} />
        <Route path="error" element={<ErrorPayment />} />
        <Route path="delivery" element={<Delivery />} />
        <Route path="mentions-legales" element={<LegalesInfos />} />
        <Route path="CGV" element={<CGV />} />
        <Route path="delivery-infos" element={<DeliveryInfos />} />
      </Routes>
    </div>
  );
};

export default Router;
