import React, { useState, useEffect } from 'react';
import Button from '../button/button.component';
import './form-delivery.styles.scss';

const FormContact = ({ cartItems, priceWithDiscount }) => {
  const [isTouchedFields, setIsTouchedFields] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const [customerInfos, setCustomerInfos] = useState({
    email: '',
    fullName: '',
    address: '',
    additionalAddress: '',
    postalCode: '',
    city: '',
    phone: '',
    country: ''
  });

  const {
    email,
    fullName,
    address,
    additionalAddress,
    postalCode,
    city,
    phone,
    country
  } = customerInfos;

  const isCompleteForm = () => {
    for (const field in customerInfos) {
      if (field !== 'additionalAddress' && customerInfos[field] === '') {
        return setIsDisabled(true);
      }
    }
    return setIsDisabled(false);
  };

  useEffect(() => {
    isCompleteForm();
  }, [customerInfos]);

  const products = cartItems
    .map((item) => {
      if (item.isFlorOrHasch) {
        let florOrHaschLabel;
        item.prices?.forEach((price) => {
          if (item.price === price.value) {
            florOrHaschLabel = price.label;
          }
        });
        return ` ${item.quantity} x ${item.name} ${florOrHaschLabel} `;
      }
      return ` ${item.quantity} x ${item.name} `;
    })
    .join('|');

  const amount = priceWithDiscount * 100;

  const customerTrns = `${products} | ${address} ${additionalAddress} | ${postalCode} | ${city} | ${country}`;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCustomerInfos((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    setIsTouchedFields((prev) => ({ ...prev, [name]: true }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!amount || !products) {
      return;
    }

    try {
      const response = await fetch(
        `https://lacanacbd.fr/getOrderId?amount=${amount}&customerTrns=${customerTrns}&email=${email}&fullName=${fullName}&phone=${phone}`
      );

      const { orderCode } = await response.json();

      if (orderCode) {
        // window.open(`https://demo.vivapayments.com/web2?ref=${orderCode}`);
        // window.open(
        //   `https://www.vivapayments.com/web/checkout?ref=${orderCode}`,
        //   '_blank'
        // );
        window.location.href = `https://www.vivapayments.com/web/checkout?ref=${orderCode}`;
      }
    } catch (error) {
      console.log('ERROR', error); /////////////////////
    }
  };

  return (
    <div className="delivery-container">
      <h3>Livraison</h3>
      <div className="radio">
        <input
          type="radio"
          id="standard"
          name="standard"
          value=""
          defaultChecked
        />
        <label>Standard - Colissimo</label>
      </div>
      <p className="subtitle">Coordonnées et adresse de livraison:</p>
      <form>
        <label>
          <input
            name="email"
            type="email"
            placeholder="email *"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!email &&
              isTouchedFields.email &&
              'Entrez une adresse e-mail valide'}
          </p>
        </label>

        <label>
          <input
            name="fullName"
            placeholder="Nom *"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!fullName &&
              isTouchedFields.fullName &&
              'Saisir votre nom et prénom'}
          </p>
        </label>

        <label>
          <input
            name="address"
            placeholder="Adresse *"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!address && isTouchedFields.address && 'Saisir une adresse'}
          </p>
        </label>

        <label>
          <input
            name="additionalAddress"
            placeholder="Complément d'adresse"
            onChange={handleChange}
          />
          <p></p>
        </label>

        <label>
          <input
            name="postalCode"
            placeholder="Code Postal *"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!postalCode &&
              isTouchedFields.postalCode &&
              'Saisir un code postal'}
          </p>
        </label>

        <label>
          <input
            name="city"
            placeholder="Ville *"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!city && isTouchedFields.city && 'Saisir une ville'}
          </p>
        </label>

        <label>
          <input
            name="phone"
            placeholder="Téléphone *"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!phone &&
              isTouchedFields.phone &&
              'Saisissez un numéro de téléphone pour ce mode de livraison'}
          </p>
        </label>

        <label>
          <input
            name="country"
            placeholder="Pays *"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="red">
            {!country && isTouchedFields.country && 'Saisir une pays'}
          </p>
        </label>

        <Button onClick={handleSubmit} disabled={isDisabled} type="submit">
          Continuer vers le paiement
        </Button>
      </form>
    </div>
  );
};
export default FormContact;
