import React from 'react';
import { mentionsLegalesText } from './text-mentions-legales';

const MentionsLegales = () => {
  const { title, subtitle, textOne, textTwo, textThree, textFour, texFive } =
    mentionsLegalesText;
  return (
    <div className="mentions-legales">
      <h1 className='title'>{title}</h1>
      <p className='article'>{subtitle}</p>
      <p>{textOne}</p>
      <p>{textTwo}</p>
      <p>{textThree}</p>
      <p>{textFour}</p>
      <p>{texFive}</p>
    </div>
  );
};

export default MentionsLegales;
