import React from 'react';
import { Link } from 'react-router-dom';

import ProductCard from '../product-card/product-card.component';
import './category-preview.styles.scss';

const CategoryPreview = ({ title, products }) => {
  return (
    <div className="category-preview-container">
      <h1 className="cursor title">
        <Link to={title}>{title.toUpperCase()}</Link>
      </h1>
      <div className="preview">
        {products.items
          // .filter((_, idx) => idx < 4)
          .map((product) => (
            <ProductCard
              category={title.toLowerCase()}
              key={product.id}
              product={product}
            />
          ))}
      </div>
    </div>
  );
};

export default CategoryPreview;
