import { Outlet } from 'react-router-dom';
import Directory from '../../components/directory/directory.component';
import { whoWeAreText } from './textWhoWeAre';
import '../category/category.styles.scss';
import './home.styles.scss';
const Home = () => {
  const categories = [
    {
      id: 1,
      title: 'Fleurs / Hasch',
      imageUrl: 'https://www.lra.fr/wp-content/uploads/2021/09/fleur-cbd.jpg',
      route: 'shop/fleurs et hasch'
    },
    {
      id: 2,
      title: 'Huiles',
      imageUrl:
        'https://sauvonsnotrepeau.fr/wp-content/uploads/2020/08/acheter-meilleure-huile-cbd-cannabidiol-fabriquer-cosmetique-promo.jpg',
      route: 'shop/huiles'
    },
    {
      id: 3,
      title: 'Infusions',
      imageUrl:
        'https://kilogrammes.com/wp-content/uploads/2021/04/infusion-cbd-1.jpg',
      route: 'shop/infusions'
    },
    {
      id: 4,
      title: 'Cosmétiques',
      imageUrl:
        'https://greenexperts.fr/wp-content/uploads/2023/03/cosmetique-et-cbd-1-green-experts-1030x687.jpeg',
      route: 'shop/cosmetiques'
    },
    {
      id: 5,
      title: 'Vap pen',
      imageUrl:
        'https://www.snacbd.fr/wp-content/uploads/2021/07/Vaporter-CBD-en-voyage.jpg',
      // imageUrl: 'https://cdn.myshoptet.com/usr/www.cbd-club.cz/user/shop/big/320_b4d2fc39-ea42-406e-9b0c-3364d589bc581.jpg?621406a7',
      route: 'shop/vap pen'
    }
  ];

  return (
    <div>
      <Directory categories={categories} />
      <h3 className="title-text">QUI SOMMES NOUS?</h3>
      <div className="category-information">{whoWeAreText}</div>

      <div className="video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/DjwNDIrxe8Y"
          title="YouTube video player"
          // frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <Outlet />
    </div>
  );
};

export default Home;
