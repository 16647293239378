import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CartContext } from '../../contexts/cart.context';

import Button from '../button/button.component';
import CartItem from '../cart-item/cart-item.component';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { formatViewNumber } from '../../utils/utils';
import './cart-dropdown.styles.scss';

const CartDropdown = () => {
  const { cartItems, cartTotal } = useContext(CartContext);
  const navigate = useNavigate();

  const goToCheckoutHandler = () => {
    navigate('/checkout');
  };

  const isDisabled = cartItems.length === 0;

  const buttonContent =
    cartTotal > 0 ? (
      <div className="button-dropdown">
        <div className="image">
          <AiOutlineShoppingCart />
        </div>
        <div className="price">{`( ${formatViewNumber(cartTotal)} € )`}</div>
      </div>
    ) : (
      'Panier vide'
    );
  return (
    <div className="cart-dropdown-container">
      <div className="cart-items">
        {cartItems.map((item) => (
          <CartItem key={item.id} cartItem={item} />
        ))}
      </div>
      <Button disabled={isDisabled} onClick={goToCheckoutHandler}>
        {buttonContent}
      </Button>
    </div>
  );
};

export default CartDropdown;
