import React, { useState, useEffect } from 'react';
import ProductCard from '../product-card/product-card.component';

const CategoryWithTitles = ({ products, category }) => {
  const [splitCategory, setSplitCategory] = useState({});

  useEffect(() => {
    split();
  }, [products, category]);

  const split = () => {
    const splitList = {};

    products.map((product) => {
      const title = product.categoryTitle;
      if (!splitList[title]) {
        splitList[title] = [];
      }
      splitList[title].push(product);
    });

    setSplitCategory(splitList);
  };

  const view = () => {
    return Object.keys(splitCategory).map((categoryName) => {
      return (
        <div key={categoryName}>
          <h1 className='split-title'>{categoryName.toUpperCase()}</h1>
          <div className="category-container">
            {splitCategory[categoryName].map((product) => (
              <ProductCard
                key={product?.id}
                product={product}
                category={category}
              />
            ))}
          </div>
        </div>
      );
    });
  };
  return <div>{view()}</div>;
};

export default CategoryWithTitles;
