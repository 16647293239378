import { useState } from 'react';
import { formatViewNumber } from '../../utils/utils';
import Button from '../button/button.component';
import ModalItem from '../modal-item/modal-item';
import './product-card.styles.scss';

const ProductCard = ({ product, category }) => {
  const { name, lowerPrice, imageUrl, price } = product;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="product-card-container">
      <img onClick={handleOpen} src={imageUrl} alt={`${name}`} />
      <div className="footer">
        <span className="name">{name}</span>
        <span className="price">{lowerPrice || `${formatViewNumber(price)} €`}</span>
      </div>

      <Button buttonType="showProduct" onClick={handleOpen}>
        Voir le produit
      </Button>

      <ModalItem
        handleClose={handleClose}
        open={open}
        product={product}
        category={category}
      />
    </div>
  );
};

export default ProductCard;
