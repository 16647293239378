import React, { useContext, useState, useEffect } from 'react';
import FormDelivery from '../../components/form-delivery/form-delivery.component';
import SummaryView from '../../components/summary/summary.view.component';
import { CartContext } from '../../contexts/cart.context';
import { formatViewNumber } from '../../utils/utils';
import './delivery.styles.scss';

const Delivery = () => {
  const { cartItems, cartTotal } = useContext(CartContext);
  const [discount, setDiscount] = useState(0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(cartTotal);

  const deliveryPrice = cartTotal > 50 ? 0 : 5.99;

  useEffect(() => {
    const totalPrice =
      Math.round((cartTotal + deliveryPrice - discount) * 100) / 100;

      setPriceWithDiscount(totalPrice);
  }, [cartTotal, deliveryPrice, discount]);

  const subtotal = cartItems.reduce(
    (total, cartItem) => total + cartItem.quantity * cartItem.price,
    0
  );

  const roundSubtotal = Math.round(subtotal * 100) / 100;

  return (
    <div className="container-delivery">
      <FormDelivery cartItems={cartItems} priceWithDiscount={priceWithDiscount} />
      <SummaryView
        deliveryPrice={formatViewNumber(deliveryPrice)}
        discount={discount}
        priceWithDiscount={formatViewNumber(priceWithDiscount)}
        setDiscount={setDiscount}
        subtotal={formatViewNumber(roundSubtotal)}
      />
    </div>
  );
};

export default Delivery;
