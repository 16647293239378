import React, { useState, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { BsX } from 'react-icons/bs';

import { CartContext } from '../../contexts/cart.context';
import DescriptionFlor from './modal-descriptions/description-flors/description-flor';
import DescriptionVapPen from './modal-descriptions/description-vap-pen/descriptionVapPen';
import DescriptionInfusion from './modal-descriptions/description-infusion/descriptionInfusion';
import DescriptionCosmetique from './modal-descriptions/description-cosmetique/descriptionCosmetique';
import DescriptionOil from './modal-descriptions/description-oil/description-oil';
import FlorItem from './flor-item/flor-item.component';
import BasicItem from './basic-item/basic-item.component';
import './modal-item.styles.scss';
import Button from '../button/button.component';
import { formatViewNumber } from '../../utils/utils';

const ModalItem = ({ open, handleClose, product, category }) => {
  const { addItemToCart } = useContext(CartContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [productToAdd, setProductToAdd] = useState(product);
  const isCategoryFLorHasch = category === 'fleurs et hasch';

  const closeModal = () => {
    if (!isCategoryFLorHasch) {
      setQuantity(0);
    }
    handleClose();
  };

  const addProductToCart = () => {
    addItemToCart(productToAdd, isCategoryFLorHasch);
    closeModal();
    setTimeout(() => {
      alert('Séléction ajouté à votre panier !');
    }, 100);
  };

  const createProduct = ({
    index,
    price,
    quantityToAdd = 1,
    fromSelect = false
  }) => {
    const newProduct = { ...product };
    newProduct.price = price;
    newProduct.quantity = quantityToAdd;
    fromSelect
      ? (newProduct.id = newProduct.id + index)
      : (newProduct.id = index);
    setProductToAdd(newProduct);
  };

  const description = () => {
    switch (category) {
      case 'fleurs et hasch':
        return <DescriptionFlor product={productToAdd} />;
      case 'huiles':
        return <DescriptionOil product={productToAdd} />;
      case 'infusions':
        return <DescriptionInfusion product={productToAdd} />;
      case 'cosmetiques':
        return <DescriptionCosmetique product={productToAdd} />;
      case 'vap pen':
        return <DescriptionVapPen product={productToAdd} />;
      default:
        return <div>{category}</div>;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="box">
        <div>
          <div className="button-close" onClick={closeModal}>
            <BsX size={40} />
          </div>

          <div className="main-container">
            <div className="container-image">
              <img
                className="image-modal"
                src={product.imageUrl}
                alt={`${product.name}`}
              />
            </div>
            <div className="container-right">
              <div className="container-grey-summary">
                <Typography className="title" id="modal-modal-title">
                  {product.name}
                </Typography>

                {isCategoryFLorHasch ? (
                  <FlorItem
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    setQuantity={setQuantity}
                    product={product}
                    createProduct={createProduct}
                  />
                ) : (
                  <BasicItem
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    product={product}
                    createProduct={createProduct}
                    productToAdd={productToAdd}
                  />
                )}

                <div className="total-price-container">
                  <Typography
                    className="total"
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    Total:
                  </Typography>
                  <p className="price">{formatViewNumber(totalPrice)} €</p>
                </div>

                <Button
                  className="button-validate"
                  buttonType="show-product"
                  disabled={!totalPrice}
                  onClick={addProductToCart}
                >
                  Ajouter au panier
                </Button>
              </div>
            </div>
          </div>

          <div className="description">{description()}</div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalItem;
