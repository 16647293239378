import React from 'react';
import FormContact from '../../components/form-contact/form-contact.component';

const Contact = () => {
  return (
    <div>
      <FormContact />
    </div>
  );
};

export default Contact;
