export const cgvTitles = {
  title: `CONDITIONS GÉNÉRALES DE VENTE`,
  subtitleOne: `Définitions`,
  articleOne: `Article 1 - Application des CGV et objet du Site`,
  articleTwo: `Article 2 - Création d'un compte client`,
  articleThree: `Article 3 - Modalité de souscription des commandes et descriptif du processus d’achat`,
  articleFour: `Article 4 - Prix et modalités de paiement`,
  articleFive: `Article 5 - Livraisons`,
  articleSix: `Article 6 - Droit de rétractation et formulaire de rétractation`,
  articleSeven: `Article 7 - Garantie des Produits`,
  articleSevenSubititleOne: `Garantie légale de conformité`,
  articleSevenSubititleTwo: `Garantie légale contre les vices cachés`,
  articleEight: `Article 8 - Service Client`,
  articleNine: `Article 9 - Responsabilité`,
  articleTen: `Article 10 - Droits de propriété intellectuelle`,
  articleEleven: `Article 11 - Indépendances des clauses`,
  articleTwelve: `Article 12 - Droit applicable et médiation`
};

export const firstULText = [
  {
    text: `« Site »: le site Https://lacanacbd.fr et l'ensemble de ses pages.`
  },
  {
    text: `« Produits » ou « Services »: l'ensemble des produits (matériels) et services (prestations) qu'il est possible d'acheter ou
  auxquels il est possible de souscrire sur le Site.`
  },
  {
    text: `« Vendeur »: LEM, personne morale ou physique, proposant ses Produits ou Services sur le Site.`
  },
  {
    text: `« Client »: l'internaute, particulier ou professionnel, effectuant un achat de Produit(s) ou Service(s) sur le Site.`
  },
  {
    text: `« Consommateur », conformément à la définition de l'article préliminaire du Code de la consommation: « toute personne
  physique qui agit à des fins qui n'entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou libérale ».`
  }
];

export const secondULText = [
  {
    text: `de tout dommage direct ou indirect, notamment en ce qui concerne les pertes de profits, le manque à gagner, les pertes de
  clientèle, de données pouvant entre autres résulter de l'utilisation du Site, ou au contraire de l'impossibilité de son utilisation;`
  },
  {
    text: `d'un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise utilisation, d'une mauvaise configuration de l'ordinateur
  du Client, ou encore de l'emploi d'un navigateur peu usité par le Client;`
  },
  {
    text: `du Client, ou encore de l'emploi d'un navigateur peu usité par le Client ;
  du contenu des publicités et autres liens ou sources externes accessibles par les Clients à partir du Site.`
  }
];

export const thirdULText = [
  { text: `MEDIATEUR DE LA CONSOMMATION AGREE - DEVIGNY MEDIATION` },
  { text: `contact@devignymediation.fr` },
  { text: `https://www.devignymediation.fr/consommateurs.php` }
];

export const definitionText = [
  {
    text: `Les présentes Conditions Générales de Vente (ci-après les « CGV ») sont proposées par LEM SARL, au capital de 1500 €,
  entreprise immatriculée au RCS de Evry sous le numéro 909188179, dont le siège social est sis au 2 rue de vaucluse, 91940 Les
  ulis (ci-après « LEM »).`
  },
  {
    text: `L'internaute visitant le Site et intéressé par les Produits et Services proposés par le Vendeur est invité à lire attentivement ces CGV,
  à les imprimer et/ou à les sauvegarder sur un support durable, avant de procéder à une commande sur le Site.`
  },
  {
    text: `Le Client reconnaît avoir pris connaissance des CGV et les accepte intégralement et sans réserve.`
  },
  { text: `On désignera par la suite:` }
];

export const articleOneText = [
  {
    text: `Le Vendeur se réserve le droit de modifier à tout moment les CGV en publiant une nouvelle version de ces dernières sur le Site.
  Les CGV applicables au Client sont celles en vigueur au jour de sa commande sur le Site.`
  },
  {
    text: `Les informations légales concernant l'hébergeur et l'éditeur du Site, la collecte et le traitement des données personnelles et les
  conditions d'utilisation du Site sont fournies dans les conditions générales d'utilisation, les mentions légales et la charte de données
  du présent Site.`
  },
  {
    text: `Le Site est d’accès libre et gratuit à tout Client. L'acquisition d'un Produit ou d’un Service suppose l’acceptation, par le Client, de
  l’intégralité des présentes CGV, qui reconnaît du même fait en avoir pris pleinement connaissance. Cette acceptation pourra
  consister par exemple, pour le Client, à cocher la case correspondant à la phrase d'acceptation des présentes CGV, ayant par
  exemple la mention « Je reconnais avoir lu et accepté l’ensemble des conditions générales du Site ». Le fait de cocher cette case
  sera réputé avoir la même valeur qu’une signature manuscrite de la part du Client.`
  },
  {
    text: `L'acceptation des présentes CGV suppose de la part des Clients qu'ils jouissent de la capacité juridique nécessaire pour cela. Si le
  Client est mineur ou ne dispose pas de cette capacité juridique, il déclare avoir l'autorisation d'un tuteur, d'un curateur ou de son
  représentant légal.`
  },
  {
    text: `Le Client reconnaît la valeur de preuve des systèmes d'enregistrement automatique du Vendeur et, sauf pour lui d'apporter une
  preuve contraire, il renonce à les contester en cas de litige.`
  },
  {
    text: `L'Editeur met à la disposition du Client, sur son Site, une charte de confidentialité spécifiant l’ensemble des informations afférentes
  à l’utilisation des données à caractère personnel du Client collectées par l'Editeur et aux droits dont le Client dispose vis-à-vis de
  ces données personnelles. La politique de confidentialité des données fait partie des CGV. L'acceptation des présentes CGV
  implique par conséquent l'acceptation de la politique de confidentialité des données.`
  }
];

export const articleTwoText = [
  {
    text: `Le renseignement d'informations par le client au moment de la validation de la livraison ou la création d’un « compte client » est un préalable indispensable à toute commande du Client sur le présent Site. A cette fin, le
  Client sera invité à fournir un certain nombre d’informations personnelles tels que ses nom et prénom, son adresse email, son
  adresse postale et son numéro de téléphone, cette liste n’étant pas exhaustive. A ce titre, le Client s’engage à fournir des
  informations exactes. Le Client est responsable de la mise à jour de ses données. Il doit donc aviser sans délai le Vendeur en cas
  de changement. Le Client est le seul responsable de la véracité, de l'exactitude et de la pertinence des données fournies.`
  },
  {
    text: `Le Client inscrit au Site a la possibilité d'y accéder en se connectant grâce à ses identifiants (adresse e-mail définie lors de son
    inscription et mot de passe) ou éventuellement en utilisant des systèmes tels que des boutons de connexion tiers de réseaux
    sociaux. Le Client est entièrement responsable de la protection du mot de passe qu’il a choisi. Il est encouragé à utiliser des mots
    de passe complexes. En cas d’oubli de mot de passe, le Client a la possibilité d’en générer un nouveau. Ce mot de passe constitue
    la garantie de la confidentialité des informations contenues dans sa rubrique « mon compte » et le Client s'interdit donc de le
    transmettre ou de le communiquer à un tiers. A défaut, le Vendeur ne pourra être tenu pour responsable des accès non autorisés
    au compte d'un Client.`
  },
  {
    text: `Le compte client permet au Client de consulter toutes ses commandes effectuées sur le Site. Si les données contenues dans la
  rubrique compte client venaient à disparaître à la suite d’une panne technique ou d’un cas de force majeure, la responsabilité du
  Vendeur ne pourrait être engagée, ces informations n’ayant aucune valeur probante mais uniquement un caractère informatif. Les
  pages relatives au compte client sont librement imprimables par le Client titulaire du compte en question mais ne constituent
  nullement une preuve, elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace de ses commandes ou
  contributions par le Client.`
  },
  {
    text: `Chaque Client est libre de fermer son compte sur le Site. Pour ceci, il doit adresser un e-mail au Vendeur en indiquant qu’il
  souhaite supprimer son compte. Aucune récupération de ses données ne sera alors possible.`
  },
  {
    text: `Le Vendeur se réserve le droit exclusif de supprimer le compte de tout Client qui aurait contrevenu aux présentes CGV (notamment
    et sans que cet exemple n’ait un quelconque caractère exhaustif, lorsque le Client aura fourni sciemment des informations
    erronées, lors de son inscription et de la constitution de son espace personnel) ou encore tout compte inactif depuis au moins une
    année. Ladite suppression ne sera pas susceptible de constituer un dommage pour le Client qui ne pourra prétendre à aucune
    indemnité de ce fait. Cette exclusion n’est pas exclusive de la possibilité, pour le Vendeur, d’entreprendre des poursuites d’ordre
    judiciaire à l’encontre du Client, lorsque les faits l’auront justifié.`
  }
];

export const articleThreeText = [
  {
    text: `Les Produits et Services proposés sont ceux qui figurent dans le catalogue publié sur le Site. Ces Produits et Services sont
  proposés dans la limite des stocks disponibles. Chaque Produit est accompagné d’un descriptif établi par le Vendeur en fonction
  des descriptifs fournis par le fournisseur.`
  },
  {
    text: `Les photographies des Produits du catalogue reflètent une image fidèle des Produits et Services proposés mais ne sont pas
  constitutives d’un engagement contractuel dans la mesure où elles ne peuvent pas assurer une parfaite similitude avec les Produits
  physiques.`
  },
  {
    text: `On définira ci-dessous comme « Panier » l’objet immatériel regroupant l’ensemble des Produits ou Services sélectionnés par le
  Client du Site en vue d’un achat en ayant cliqué sur ces éléments. Afin de procéder à sa commande, le Client choisit le ou les
  Produit(s) qu’il souhaite commander en les ajoutant à son « Panier » dont le contenu peut être modifié à tout moment.`
  },
  {
    text: `Dès lors que le Client estimera avoir sélectionné et ajouté à son panier tous les Produits qu’il souhaite acheter, il aura la possibilité,
  pour valider sa commande, d’accéder à son panier en cliquant sur le bouton prévu à cet effet. Il sera alors redirigé sur une page
  récapitulative sur laquelle lui seront communiqués le nombre et les caractéristiques des Produits commandés, ainsi que leur prix
  unitaire.`
  },
  {
    text: `S'il souhaite valider sa commande, le Client devra cocher la case relative à la ratification des présentes CGV et cliquer sur le
  bouton de validation. Le Client sera alors redirigé sur une page dans laquelle il devra remplir les champs de formulaire de
  commande. Il devra dans ce dernier cas renseigner un certain nombre de données personnelles le concernant, nécessaires au bon
  déroulement de la commande.`
  },
  {
    text: `Toutes les commandes passées sur le Site doivent être dûment remplies et doivent préciser ces informations nécessaires. Le
  Client pourra faire des changements, des corrections, des ajouts, ou annuler la commande, et ce, jusqu'à la validation de celle-ci.`
  },
  {
    text: `Dès lors que le Client aura rempli le formulaire, il sera alors invité à effectuer son paiement avec les moyens de paiements listés
  dans la section de ces CGV relative aux paiements. Après quelques instants le Client se verra adresser un courrier électronique de
  confirmation de la commande, lui rappelant le contenu de la commande et le prix de celle-ci.`
  },
  {
    text: `Les Produits vendus restent la propriété du Vendeur jusqu’à complet paiement de leur prix, conformément à la présente clause de
  réserve de propriété.`
  }
];

export const articleFourText = [
  {
    text: `Sauf mention contraire, les prix figurant dans le catalogue sont des prix entendus en Euros toutes taxes comprises (TTC), tenant
  compte de la TVA applicable au jour de la commande et hors éventuelle participation aux frais de traitement et d'expédition.`
  },
  // { text: `Le présent Site propose la vente en ligne de .` },
  {
    text: `LEM se réserve le droit à tout moment de modifier ses prix et de répercuter, si applicable, tout changement du taux de TVA en
  vigueur sur le prix des Produits ou Services proposés sur le Site. Néanmoins, le prix figurant au catalogue le jour de la commande
  sera le seul applicable au Client.`
  },
  {
    text: `Le Client peut passer commande sur le présent Site et peut effectuer son règlement par Carte bancaire.Les paiements par carte
  bancaire se font au moyen de transactions sécurisées fournies par un prestataire de plateforme de paiement en ligne.`
  },
  {
    text: `Le présent Site n’a accès à aucune donnée relative aux moyens de paiement du Client. Le paiement est effectué directement entre
  les mains de la banque ou du prestataire de paiement recevant le paiement du Client. En cas de paiement par chèque ou virement
  bancaire, les délais de livraison définis à l’article « Livraisons » des présentes CGV ne commencent à courir qu’à compter de la
  date de réception effective du paiement par le Vendeur, ce dernier pouvant en apporter la preuve par tous moyens. La disponibilité
  des Produits est indiquée sur le Site, dans la fiche descriptive de chaque Produit.`
  },
  {
    text: `LEM archivera les bons de commandes et les factures sur un support fiable et durable constituant une copie fidèle. Les registres
  informatisés seront considérés par les parties comme preuve des communications, commandes, paiements et transactions
  intervenus entre les parties.`
  }
];

export const articleFiveText = [
  {
    text: `Les frais de livraison seront indiqués au Client avant tout règlement et ne concernent que les livraisons effectuées en Union
  européenne. Pour tout autre lieu de livraison, il appartiendra au Client de prendre contact avec le service clientèle.`
  },
  {
    text: `En cas de livraison d'un Produit en dehors du territoire de l'Union européenne et dans les Dom-Tom, le Client se déclare
  l'importateur du Produit et accepte qu'en pareil cas le Vendeur peut être dans l'impossibilité matérielle de lui communiquer une
  information exacte sur le montant total des frais afférents aux droits et formalités de douanes ou des taxes à l'importation
  applicables dans le pays où la livraison du Produit est demandée.`
  },
  {
    text: `Sauf mention contraire affichée sur le Site lors du processus de commande ou dans la description des Produits commandés, le
  Vendeur s’engage dans tous les cas à livrer les Produits dans un délai maximum de trente (30) jours après la conclusion du contrat
  avec un Client Consommateur.`
  },
  {
    text: `Le Client peut refuser un colis au moment de la livraison s'il constate une anomalie concernant la livraison (avarie, Produit
    manquant par rapport au bon de livraison, colis endommagé, Produits cassés...) ; toute anomalie devra alors impérativement être
    indiquée par le Client sur le bon de livraison, sous forme de réserves manuscrites, accompagnées de la signature du Client. Pour
    exercer son droit de refus, le Client devra ouvrir le ou les colis détériorés ou défectueux en présence du transporteur et lui faire
    reprendre la marchandise détériorée. A défaut de se conformer à ces prescriptions, le Client ne pourra pas exercer son droit de
    refus, et le Vendeur ne sera pas tenu d'accéder à la demande d'exercice du droit de refus du Client.`
  },
  {
    text: `Si le colis du Client est retourné au Vendeur par la Poste ou par d’autres prestataires postaux, le Vendeur contactera le Client à
  réception du colis en retour pour lui demander la suite à donner à sa commande. Si le Client a refusé par erreur le colis il pourra en
  demander le renvoi en s’acquittant au préalable du paiement des frais postaux pour le nouvel envoi. Les frais postaux devront être
  acquittés même pour les commandes dont les frais de port étaient offerts lors de la commande.`
  },
  {
    text: `En cas d’erreur de livraison ou d’échange (si le droit de rétractation est applicable, c'est à dire si le Client est un Consommateur et
  que le contrat passé pour acquérir le Produit ou Service permet la rétractation, selon l'article L.221-18 et suivants du Code de la
  consommation), tout produit à échanger ou à rembourser devra être retourné au Vendeur dans son intégralité et en parfait état.
  Toute défectuosité résultant d’une maladresse ou d’une fausse manœuvre du Client ne pourra être imputée au Vendeur.`
  },
  {
    text: `Tout retard de livraison par rapport à la date ou au délai indiqué(e) au Client Consommateur lors de sa commande ou, à défaut
  d'indication de date ou délai lors de la commande, supérieur à trente (30) jours à compter de la conclusion du contrat peut
  entraîner la résolution de la vente à l’initiative du Client Consommateur, sur demande écrite de sa part par lettre recommandée
  avec accusé de réception, si après avoir enjoint le Vendeur d’effectuer la livraison il ne s’est pas exécuté. Le Client Consommateur
  sera alors remboursé, au plus tard dans les quatorze (14) jours suivant la date à laquelle le contrat a été dénoncé, de la totalité des
  sommes versées. La présente clause n’a pas vocation à s’appliquer si le retard de livraison est dû à un cas de force majeure.`
  }
];

export const articleSixText = [
  {
    text: `Conformément à l'article L.221-18 du Code de la consommation, et si le droit de rétractation est applicable, le Client Consommateur dispose
  d'un délai de quatorze (14) jours ouvrables à compter de la date de réception du Produit de sa commande ou de la conclusion du contrat
  pour les prestations de services, pour se rétracter. Il sera tenu de retourner tout Produit ne lui convenant pas et demander l'échange ou le
  remboursement sans pénalité, à l'exception des frais de retour, sous quatorze jours à compter de la réception par LEM de la demande de
  remboursement.`
  },
  {
    text: `Le Produit devra obligatoirement être retourné en parfait état. Le cas échéant, il devra être accompagné de tous ses accessoires. Le Client
  Consommateur peut trouver ci-dessous un formulaire type de rétractation pour une commande passée sur le Site, à adresser à LEM. Il est
  entendu que le Client supportera les frais de renvoi du Produit en cas de rétractation, ainsi que le coût de renvoi du Produit si celui-ci, en
  raison de sa nature, ne peut normalement être renvoyé par la Poste.`
  },
  {
    text: `Si les obligations précédentes ne sont pas effectuées, le Client perdra son droit de rétractation et le Produit lui sera retourné à ses frais.`
  },
  {
    text: `Il est recommandé au Client d’effectuer le retour par une solution permettant un suivi du colis. Dans le cas contraire, si le colis retourné ne
  parvenait pas au Vendeur, il ne sera pas possible de lancer une enquête auprès des services postaux afin de leur demander de localiser ce
  dernier.`
  },
  {
    text: `Le remboursement sera effectué en utilisant le même moyen de paiement que celui choisi par le Client pour la transaction initiale, sauf
  accord exprès du Client pour que le Vendeur utilise un autre mode de paiement, et dans la mesure où le remboursement n'occasionne pas
  de frais pour le Client.`
  },
  {
    text: `Le Vendeur se réserve également le droit de différer le remboursement jusqu'à réception du Produit ou aussi longtemps que le Client n'aura
  pas démontré qu'il a fait l'expédition du Produit, si une telle démonstration n'a pas eu lieu précédemment.`
  },
  {
    text: `En cas de dépréciation des Produits résultant de manipulations autres que celles nécessaires pour établir la nature, les caractéristiques et le
  bon fonctionnement du ou des Produit(s), la responsabilité du Client pourra être engagée.`
  }
  // { text: `Conformément à l'article L221-5 du Code de la consommation, ("loi Hamon") de juin 2014, le Client Consommateur peut trouver ci-dessous
  // un formulaire type de rétractation pour une commande passée sur le site:` },
];

///
export const articleSevenTextOne = [
  {
    text: `Lorsqu'il agit en garantie légale de conformité, le consommateur bénéficie d’un délai de deux ans à compter de la délivrance du bien
  pour agir ; il peut choisir entre la réparation ou le remplacement du bien, sous réserve des conditions de coût prévues par l’article
  L.217-9 du Code de la consommation ; sauf pour les biens d’occasion, il est dispensé de prouver l’existence du défaut de conformité du
  bien durant les six mois suivant la délivrance du bien, délai porté à 24 mois à compter du 18 mars 2016.`
  },
  {
    text: `La garantie légale de conformité s’applique indépendamment de la garantie commerciale éventuellement consentie.`
  },
  {
    text: `Le consommateur peut décider de mettre en œuvre la garantie contre les défauts cachés de la chose vendue au sens de l’article 1641
  du Code civil, à moins que le vendeur n'ait stipulé qu'il ne sera obligé à aucune garantie ; dans l'hypothèse d'une mise en œuvre de
  cette garantie, l'acheteur a le choix entre la résolution de la vente ou une réduction du prix de vente conformément à l’article 1644 du
  Code civil. Il dispose d’un délai de deux années à compter de la découverte du vice.`
  },
  {
    text: `Le report, la suspension ou l’interruption de la prescription ne peut avoir pour effet de porter le délai de prescription extinctive au-delà
  de vingt ans à compter du jour de la naissance du droit conformément à l'article 2232 du Code civil.`
  },
  {
    text: `Tous les articles acquis sur le présent site bénéficient des garanties légales suivantes, prévues par le Code Civil ;`
  }
];

export const articleSevenTextTwo = [
  {
    text: `Selon les articles L.217-4 et suivants du Code de la consommation, le Vendeur est tenu de livrer un bien conforme au contrat
  conclu avec le Client Consommateur et de répondre des défauts de conformité existant pendant la délivrance du Produit. La
  garantie de conformité pourra s'exercer si un défaut devait exister le jour de la prise de possession du Produit. Toutefois, lorsque le
  défaut est apparu dans les 24 mois qui suivent cette date (ou dans les 6 mois si la commande a eu lieu avant le 18 mars 2016 ou
  que le Produit est vendu d'occasion), il est présumé remplir cette condition. Mais, conformément à l'article L.217-7 du Code de la
  Consommation, « le Vendeur peut combattre cette présomption si celle-ci n'est pas compatible avec la nature du [Produit] ou le
  défaut de conformité invoqué ».`
  },
  {
    text: `En revanche, passé ce délai de 24 mois (ou de 6 mois si la commande a eu lieu avant le 18 mars 2016 ou que le produit est vendu
    d'occasion), il reviendra au Client de prouver que le défaut existait bien au moment de la prise de possession du Produit.`
  },
  {
    text: `Conformément à l'article L.217-9 du Code de la consommation: « en cas de défaut de conformité l'acheteur choisit entre la
  réparation et le remplacement du bien. Toutefois, le vendeur peut ne pas procéder selon le choix de l'acheteur si ce choix entraîne
  un coût manifestement disproportionné au regard de l'autre modalité, compte tenu de la valeur du bien ou de l'importance du
  défaut. Il est alors tenu de procéder, sauf impossibilité, selon la modalité non choisie par l'acheteur ».`
  }
];

export const articleSevenTextThree = [
  {
    text: `Selon les articles 1641 à 1649 du Code civil, le Client pourra demander l'exercice de la garantie de vices cachés si les défauts
  présentés n'apparaissaient pas lors de l'achat, étaient antérieurs à l'achat (et donc ne pas résulter de l'usure normale du Produit
  par exemple), et sont suffisamment graves (le défaut doit soit rendre le Produit impropre à l'usage auquel il est destiné, soit
  diminuer cet usage dans une mesure telle que l'acheteur n'aurait pas acheté le Produit ou ne l'aurait pas acheté à un tel prix s'il
  avait connu le défaut).`
  },
  {
    text: `Les réclamations, demandes d'échange ou de remboursement pour un Produit non conforme doivent s’effectuer par courrier postal
  ou par mail aux adresses indiquées dans les mentions légales du site.`
  },
  {
    text: `En cas de non-conformité d’un Produit livré, il pourra être retourné au Vendeur qui procédera à son échange. En cas d'impossibilité
  d'échange du Produit (Produit obsolète, rupture de stock, etc.) le Client sera remboursé par chèque ou virement du montant de sa
  commande. Les frais de la procédure d'échange ou de remboursement (notamment les frais de port de retour du Produit) sont
  alors à la charge du Vendeur.`
  }
];

export const articleEightText = [
  {
    text: `Le service clientèle du présent Site est accessible par courrier électronique à l’adresse suivante: Lacanacbd@gmail.com ou par
  courrier postal à l’adresse indiquée dans les mentions légales.`
  },
  {
    text: `LEM met aussi à disposition de ses Clients une hotline, ou assistance téléphonique, pour répondre à leurs questions. L’assistance
  téléphonique peut être contactée par téléphone au (numéro non surtaxé).`
  }
];

export const articleNineText = [
  {
    text: `Le Vendeur LEM ne saurait être tenu pour responsable de l'inexécution du contrat conclu due à la survenance d'un événement de
  force majeure. Concernant les Produits achetés, le Vendeur n'encourra aucune responsabilité pour tous dommages indirects du fait
  des présentes, perte d'exploitation, perte de profit, dommages ou frais, qui pourraient survenir.`
  },
  {
    text: `Le choix et l'achat d'un Produit ou d'un Service sont placés sous l'unique responsabilité du Client. L'impossibilité totale ou partielle
  d'utiliser les Produits notamment pour cause d'incompatibilité du matériel ne peut donner lieu à aucun dédommagement,
  remboursement ou mise en cause de la responsabilité du Vendeur, sauf dans le cas d'un vice caché avéré, de non-conformité, de
  défectuosité ou d'exercice du droit de rétractation si applicable, c'est à dire si le Client n'est pas Client Consommateur et que le
  contrat passé pour acquérir le Produit ou le Service permet la rétractation, selon l'article L 221-18 et suivants du Code de la
  consommation.`
  },
  {
    text: `Les photographies et visuels des Produits présentés sur le Site n’ont aucun caractère contractuel, la responsabilité du Vendeur ne
  saurait donc être engagée si les caractéristiques des Produits diffèrent des visuels présents sur le Site ou si ces derniers sont
  erronés ou incomplets.`
  },
  {
    text: `Le Client admet expressément utiliser le Site à ses propres risques et sous sa responsabilité exclusive. Le Site fournit au Client des
  informations à titre indicatif, avec des imperfections, erreurs, omissions, inexactitudes et autres ambivalences susceptibles d'exister.
  En tout état de cause, LEM ne pourra en aucun cas être tenu responsable:`
  }
];

export const articleTenText = [
  {
    text: `Tous les éléments du présent Site appartiennent au Vendeur ou à un tiers mandataire, ou sont utilisés par le Vendeur avec
  l'autorisation de leurs propriétaires.`
  },
  {
    text: `Toute reproduction, représentation, adaptation des logos, contenus textuels, pictographiques ou vidéos, sans que cette
  énumération ne soit limitative, est rigoureusement interdite et s’apparente à de la contrefaçon.`
  },
  {
    text: `Tout Client qui se rendrait coupable de contrefaçon serait susceptible de voir son compte supprimé sans préavis ni indemnité et
  sans que cette suppression ne puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites judiciaires
  ultérieures à son encontre, à l’initiative du Vendeur ou de son mandataire.`
  },
  {
    text: `Le présent Site utilise des éléments (images, photographies, contenus) dont les crédits reviennent à: Crédits.`
  },
  {
    text: `Les marques et logos contenus dans le Site sont susceptibles d'être déposés par LEM, ou éventuellement par un de ses
  partenaires. Toute personne procédant à leurs représentations, reproductions, imbrications, diffusions et rediffusions encourt les
  sanctions prévues aux articles L.713-2 et suivants du Code de la propriété intellectuelle.`
  }
];

export const articleElevenText = [
  {
    text: `Si une disposition des CGV est jugée illégale, nulle ou pour toute autre raison inapplicable, alors cette disposition sera réputée
  divisible des CGV et n'affectera pas la validité et l'applicabilité des dispositions restantes.`
  },
  {
    text: `Ces présentes CGV remplacent tous accords antérieurs ou contemporains écrits ou oraux. Les CGV ne sont pas cessibles,
  transférables ou sous-licenciable par le Client lui-même.`
  },
  {
    text: `Une version imprimée des CGV et de tous les avis donnés sous forme électronique pourra être demandée dans des procédures
  judiciaires ou administratives en rapport avec les CGV. Les parties conviennent que toute la correspondance relative à ces CGV
  doit être rédigée dans la langue française.`
  }
];

export const articleTwelveText = [
  {
    text: `Selon l'article L.612-1 du Code de la consommation, il est rappelé que « tout consommateur a le droit de recourir gratuitement à un
  médiateur de la consommation en vue de la résolution amiable du litige qui l’oppose à un professionnel. A cet effet, le professionnel
  garantit au consommateur le recours effectif à un dispositif de médiation de la consommation ».`
  },
  {
    text: `Il est rappelé que la médiation n'est pas obligatoire mais uniquement proposée afin de résoudre les litiges en évitant un recours à la
  justice.`
  },
  {
    text: `A ce titre LEM propose à ses Clients Consommateurs, dans le cadre de litiges qui n'auraient pas trouvé résolution de manière
  amiable, la médiation d'un médiateur de la consommation, dont les coordonnées sont les suivantes:`
  }
];
