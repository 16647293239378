export const categoriesText = {
  'vap pen': `Elue meilleure vape de l’année 2021 les cartouches Golden Buds au CBD sont unique avec une silhouette  fine et discrete pour une prise en main facile.
  Produit 100% naturel élaboré par des experts «Golden buds ».
  Le CBD a l’intérieur est issue d’un distillat full spectrum  associé à des terpènes naturelles de Californie.
   Ce n’est donc pas un e-liquide, mais bien une extraction de CBD concentrée pure a 85% (50% de CBD et 35% d’autres Cannabinoïdes).`,

  huiles: `Découvrez notre sélection d’huiles full spectrum aux milles vertus de bien-être. Disponibles avec une base huilde de chanvre ou huile de coco.
  En complément du CBD, laissez-vous tenter par le CBG,excellent pour les problèmes de digestion et les règles douloureuses, ou bien encore par le CBN, 
  bénéfique pour améliorer le sommeil.`,

  infusions: `Découvrez nos créations originales d’infusions avec du CBD et des fruits secs, garanties 100% bio. Grâce aux infusions Mijane, offrez-vous un moment de bien-être.`,

  cosmetiques: `Nos cosmétiques au CBD permettent de profiter de l’effet du cannabidiol sur l’épiderme. En associant plusieurs produits actifs 
  naturels à notre CBD de haute qualité, l’action de ce dernier est renforcée, sans assécher votre peau.
  Vous pourrez pleinement profiter des bienfaits du cannabidiol en application topicale: apaisement, relaxation, récupératrice et hydratante.
  Vous pourrez en faire votre allié et profiter de moment de relaxation pour vos routines de soins.`,

  'fleurs et hasch': `Chez Lacana nous travaillons directement avec les producteurs.
  Notre gamme de fleurs se décompose en deux catégories:
  Indoor: cultivé en intérieur , sous lampes, ces produits sont de très grande qualité puisque les conditions hydrométriques (chaleur, humidité, etc) sont contrôlées et donnent ainsi des fleurs  plus gouteuses et un meilleur aspect visuel. 
  Greenhouse: cultivé sous serre en extérieur, ce type de fleurs sont moins feuillus et plus denses qu’en extérieur. C’et un excellent rapport qualité-prix.
  Chez Lacana nous testons tous nos produits pour ne garder que les meilleures variétés au niveau du goût et de l’aspect visuel.
  D’ailleurs, tous nos produits sont testés en laboratoire et sont en dessous de 0,3% de THC pour nous conformer avec la loi française en vigueur.`
};