export const navLinks = [
  {
    value: '/shop/fleurs et hasch',
    label: 'FLEURS ET HASCH'
  },
  {
    value: '/shop/huiles',
    label: 'HUILES'
  },
  {
    value: '/shop/infusions',
    label: 'INFUSIONS'
  },
  {
    value: '/shop/cosmetiques',
    label: 'COSMÉTIQUES'
  },
  {
    value: '/shop/vap pen',
    label: 'VAP PEN'
  }
];

export default navLinks;