import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsList, BsX, BsPerson } from 'react-icons/bs';

import { SidebarData } from './utils';
import './Sidebar.scss';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context';

function Sidebar() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = useCallback(
    () => setSidebar((prevSidebar) => !prevSidebar),
    []
  );
  const onNavigateHandler = useCallback(
    (link) => {
      navigate(link);
      window.scrollTo(0, 0);
    },
    [navigate]
  );

  const links = SidebarData.map((link) => {
    return (
      <div
        key={link.label}
        onClick={() => {
          onNavigateHandler(link.value);
        }}
        className="sidebar-link-category"
      >
        {link.label}
      </div>
    );
  });

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = sidebar ? 'hidden' : 'auto';
    return () => {
      body.style.overflow = 'auto';
    };
  }, [sidebar])

  return (
    <div className="sidebar">
      <div className="menu-bars">
        <BsList onClick={showSidebar} />
      </div>

      <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <div className="sidebar-open-container" onClick={showSidebar}>
          <div className="close-button">
            <BsX size={40} />
          </div>

          {links}

          {!currentUser ? (
            <div
              onClick={() => onNavigateHandler('/auth')}
              className="link-connexion"
            >
              <div className="connexion-container">
                <BsPerson size={30} />
                CONNEXION
              </div>
            </div>
          ) : (
            <div onClick={signOutUser} className="link-connexion">
              <div className="connexion-container">DECONNEXION</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
