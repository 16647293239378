import React, { useState } from 'react';
import { useWindowSize } from '../../hook/useWindowSize';
import DownArrow from '../../images/arrow.svg';
import Summary from './summary.component';
import './summary.styles.scss';

const SummaryView = ({
  priceWithDiscount,
  deliveryPrice,
  discount,
  setDiscount,
  subtotal
}) => {
  const { width } = useWindowSize();
  const [isShowSummary, setIsShowSummary] = useState(true);

  return (
    <div className="summary">
      {width <= 900 ? (
        <>
          <div
            onClick={() => setIsShowSummary(!isShowSummary)}
            className="title-container"
          >
            <p className="title-summary">
              {isShowSummary ? <p>Cacher le résumé</p> : <p>Voir le résumé</p>}
            </p>

            <img
              className={isShowSummary ? '' : 'rotate'}
              src={DownArrow}
              height="15"
              width="15"
              alt="Logo"
            />
          </div>
          {isShowSummary && (
            <Summary
              priceWithDiscount={priceWithDiscount}
              discount={discount}
              setDiscount={setDiscount}
              subtotal={subtotal}
              deliveryPrice={deliveryPrice}
            />
          )}
        </>
      ) : (
        <Summary
          priceWithDiscount={priceWithDiscount}
          discount={discount}
          setDiscount={setDiscount}
          subtotal={subtotal}
          deliveryPrice={deliveryPrice}
        />
      )}
    </div>
  );
};

export default SummaryView;
