import { useContext } from 'react';

import { CartContext } from '../../contexts/cart.context';
import { formatViewNumber } from '../../utils/utils';
import './checkout-item.styles.scss';

const CheckoutItem = ({ cartItem, isDelivery }) => {
  const { name, imageUrl, price, quantity, prices } = cartItem;

  const { clearItemFromCart, addItemToCart, removeItemToCart } =
    useContext(CartContext);

  let florLabel;
  if (prices) {
    prices?.forEach((item) => {
      if (item.value === price) {
        florLabel = item.label;
      }
    });
  }

  const clearItemHandler = () => clearItemFromCart(cartItem);
  const addItemHandler = () => addItemToCart(cartItem);
  const removeItemHandler = () => removeItemToCart(cartItem);

  return (
    <div className="checkout-item-container">
      <div className="image-container">
        <img src={imageUrl} alt={`${name}`} />
      </div>

      <span className="name-label">
        <span className="name"> {name} </span>
        {prices && <span className="label"> {florLabel} </span>}
      </span>

      <span className="quantity">
        {isDelivery ? (
          <p>Qté:</p>
        ) : (
          <div className="arrow" onClick={removeItemHandler}>
            &#10094;
          </div>
        )}
        <span className="value">{quantity}</span>
        {!isDelivery && (
          <div className="arrow" onClick={addItemHandler}>
            &#10095;
          </div>
        )}
      </span>

      <span className="price"> {formatViewNumber(price)} €</span>

      {!isDelivery && (
        <div className="remove-button" onClick={clearItemHandler}>
          &#10005;
        </div>
      )}
    </div>
  );
};

export default CheckoutItem;
