import { useRef, useEffect, useContext, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CartContext } from '../../contexts/cart.context';
import { useWindowSize } from '../../hook/useWindowSize';
import Sidebar from '../../components/Sidebar/Sidebar.component';
import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
import Logo from '../../assets/logoLaCana.png';
import MainButtonsNavigation from './main-buttons-navigation.component';
import navLinks from './utils';
import './navigation.styles.scss';

const Navigation = () => {
  const navigate = useNavigate();
  const { isCartOpen } = useContext(CartContext);
  const { width } = useWindowSize();
  const nav = useRef();

  const navigateAndScrollTop = useCallback(
    (link) => {
      navigate(link);
      window.scrollTo(0, 0);
    },
    [navigate]
  );

  const links = useMemo(
    () =>
      navLinks.map((link) => (
        <div
          key={link.label}
          onClick={() => {
            navigateAndScrollTop(link.value);
          }}
          className="link-category"
        >
          {link.label}
        </div>
      )),
    [navigateAndScrollTop]
  );

  useEffect(() => {
    let oldScrollY;

    const handleScroll = () => {
      let st = window.scrollY;

      if (oldScrollY < st && oldScrollY > 150) {
        nav.current.classList.add('scrollDown');
      } else if (oldScrollY > st) {
        nav.current.classList.remove('scrollDown');
      }
      oldScrollY = st;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={nav} className="navigation">
      <div className="logo-and-buttons-container">
        <div className="sidebar">{width < 900 && <Sidebar />}</div>
        <div
          onClick={() => {
            navigateAndScrollTop('/');
          }}
          className="logo"
        >
          <img src={Logo} alt="Logo" />
        </div>

        <MainButtonsNavigation width={width} />
        {isCartOpen && <CartDropdown />}
      </div>

      {width >= 900 && <div className="link-category-container">{links}</div>}
    </div>
  );
};

export default Navigation;
