import React, { useEffect, useContext } from 'react';

import { CartContext } from './contexts/cart.context';
import Router from './router';
import Navigation from './components/navigation/navigation.component';
import Footer from './components/footer/footer.component';
// import AOS from 'aos';

const App = () => {
  const { isCartOpen, setIsCartOpen, setCartItems } = useContext(CartContext);

  const localItems = JSON.parse(localStorage.getItem('cartItems'));

  const toggleIsCartOpen = () => {
    if (isCartOpen) {
      setIsCartOpen(false);
    }
  };

  useEffect(() => {
    if (localItems !== null) {
      setCartItems(localItems);
    }
    // AOS.init({
    //   duration: 3000
    // })
  }, []);

  return (
    <div className="App" onClick={toggleIsCartOpen}>
      <Navigation />
      <Router />
      <Footer />
    </div>
  );
};

export default App;
