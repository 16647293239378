import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { categoriesText } from './categories-text';
import ProductCard from '../../components/product-card/product-card.component';
import { CategoriesContext } from '../../contexts/categories.context';
import CategoryWithTitles from '../../components/category-with-titles/category-with-titles.component';
import './category.styles.scss';

const Category = () => {
  const { category } = useParams();
  const { categoriesMap } = useContext(CategoriesContext);
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState('');

  const createCurrentCategory = () => {
    categoriesMap.forEach((cat) => {
      if (category.toLowerCase() === cat.title.toLowerCase()) {
        setProducts(cat.items);
        setImage(cat.imageFamily);
      }
    });
  };

  useEffect(() => {
    createCurrentCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, categoriesMap]);

  return (
    <div className="category-page">
      <h1 className="category-title">{category.toUpperCase()}</h1>
      <div className="container-image-family">
        <img className="image-family" src={image} alt="Logo"></img>
      </div>
      <div className="category-information">{categoriesText[category]}</div>
      {category !== 'fleurs et hasch' ? (
        <div className="category-container">
          {products &&
            products.map((product) => (
              <ProductCard
                key={product?.id}
                product={product}
                category={category}
              />
            ))}
        </div>
      ) : (
        <CategoryWithTitles products={products} category={category} />
      )}
    </div>
  );
};

export default Category;
