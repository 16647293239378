import React from 'react';
import '../description-styles.scss';

const DescriptionVapPen = ({ product }) => {
  const isBatterie = product.id === 305;
  return (
    <div className="container-description">
      {isBatterie ? (
        <>
          <table className="container-table">
            <tbody>
              <tr>
                <td className='title'>DESCRIPTION</td>
                <td>{product.details.mainDescription}</td>
              </tr>
              <tr>
                <td className='title'>MATÉRIAU</td>
                <td>{product.details.material}</td>
              </tr>
              <tr>
                <td className='title'>TAILLE</td>
                <td>{product.details.size}</td>
              </tr>
              <tr>
                <td className='title'>PORT DE CHARGE</td>
                <td>{product.details.charge}</td>
              </tr>
              <tr>
                <td className='title'>CAPACITÉ BATTERIE</td>
                <td>{product.details.batteryCapacity}</td>
              </tr>
              <tr>
                <td className='title'>INDICATEUR LED</td>
                <td>{product.details.led}</td>
              </tr>
              <tr className="hidden">
                <td></td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <table className="container-table">
            <tbody>
              <tr>
                <td className='title'>DESCRIPTION</td>
                <td>{product.details.mainDescription}</td>
              </tr>
              <tr>
                <td className='title'>ARÔME</td>
                <td>{product.details.arome}</td>
              </tr>
              <tr>
                <td className='title'>EFFET</td>
                <td>{product.details.effect}</td>
              </tr>
              <tr className="hidden">
                <td></td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default DescriptionVapPen;
