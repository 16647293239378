import React from 'react';

const FlorItem = ({
  totalPrice,
  setTotalPrice,
  setQuantity,
  product,
  createProduct,
}) => {
  const selectOptions = product?.prices?.map((item, index) => {
    return (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    );
  });

  const handleChangeSelect = (e) => {
    const newIndex = e.target.selectedIndex / 10;
    const newPrice = parseFloat(e.target.value);

    setTotalPrice(newPrice);
    setQuantity(1);
    createProduct({ index: newIndex, price: newPrice, fromSelect: true });
  };

  return (
    <div>
      <select
        className="select-quantity"
        value={totalPrice}
        onChange={handleChangeSelect}
      >
        <option value={0}>Séléctionner une quantité</option>
        {selectOptions}
      </select>
    </div>
  );
};

export default FlorItem;
