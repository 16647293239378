import React from 'react';
import { deliveryInfosText } from './text-delivery-infos';
import '../CGV/CGV.styles.scss';

const DeliveryInfos = () => {
  const { title, subtitle, textOne, textTwo } = deliveryInfosText;
  return (
    <div className="delivery-infos">
      <h1 className="title">{title}</h1>
      <p className="article">{subtitle}</p>
      <p>{textOne}</p>
      <p>{textTwo}</p>
    </div>
  );
};

export default DeliveryInfos;
